import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { ReactComponent as LogoDark } from '../assets/filingreader-logo-dark.svg';
import { ReactComponent as LogoLight } from '../assets/filingreader-logo-light.svg';

const Navbar = ({ scrollY = 0 }) => {  // Add default value
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to check which section is currently in view
  const checkActiveSection = () => {
    const sections = ['features', 'api', 'pricing'];
    
    if (location.pathname !== '/') {
      return;
    }

    const sectionElements = sections.map(section => ({
      id: section,
      element: document.getElementById(section)
    }));

    const currentSection = sectionElements.find(({ element }) => {
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return rect.top <= 100 && rect.bottom >= 100;
    });

    setActiveSection(currentSection ? currentSection.id : '');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      window.addEventListener('scroll', checkActiveSection);
      checkActiveSection();
      return () => window.removeEventListener('scroll', checkActiveSection);
    } else {
      setActiveSection('');
    }
  }, [location.pathname]);

  // Close menu when route changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  // Function to handle section navigation
  const handleNavigation = (e, item) => {
    e.preventDefault();
    
    if (item.type === 'section') {
      if (location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById(item.href.slice(1));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      } else {
        const element = document.getElementById(item.href.slice(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
    setIsMenuOpen(false);
  };

  // Function to determine if a nav item is active
  const isActive = (href) => {
    if (href.startsWith('#')) {
      return activeSection === href.slice(1);
    } else {
      return location.pathname === href;
    }
  };

  const navItems = [
    { text: 'Features', href: '#features', type: 'section' },
    { text: 'Pricing', href: '#pricing', type: 'section' },
    { text: 'API', href: '#api', type: 'section' },
    { text: 'News', href: '/news-wire', type: 'page' },
    { text: 'Widgets', href: 'https://app.filingreader.com/widgets', type: 'external' },
    { text: 'About', href: '/about', type: 'page' },
    { text: 'Contact', href: 'mailto:info@filingreader.com', type: 'external' }
  ];

  const renderNavItems = (mobile = false) => navItems.map((item, index) => {
    // Base classes that change based on scroll position
    const baseTextClasses = scrollY > 50 || isMenuOpen
      ? 'text-light-text' // Dark text on light background when scrolled
      : 'text-white'; // White text on dark background initially

    // External links (like mailto:)
    if (item.type === 'external') {
      return (
        <a
          key={index}
          href={item.href}
          className={`relative font-medium tracking-wide transition-colors duration-300 hover:text-secondary ${
            mobile ? 'block py-3 px-6 border-b border-gray-100 text-light-text' : baseTextClasses
          }`}
        >
          {item.text}
        </a>
      );
    }

    // Section links (Features, Pricing)
    if (item.type === 'section') {
      return (
        <a
          key={index}
          href={item.href}
          onClick={(e) => handleNavigation(e, item)}
          className={`relative font-medium tracking-wide transition-colors duration-300 hover:text-secondary ${
            mobile ? 'block py-3 px-6 border-b border-gray-100 text-light-text' : baseTextClasses
          } ${isActive(item.href) ? 'text-secondary' : ''}`}
        >
          {item.text}
        </a>
      );
    }

    // Page links (About)
    return (
      <Link
        key={index}
        to={item.href}
        className={`relative font-medium tracking-wide transition-colors duration-300 hover:text-secondary ${
          mobile ? 'block py-3 px-6 border-b border-gray-100 text-light-text' : baseTextClasses
        } ${isActive(item.href) ? 'text-secondary' : ''}`}
      >
        {item.text}
      </Link>
    );
  });

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${
      scrollY > 50 || isMenuOpen ? 'bg-white shadow-lg' : 'bg-transparent'
    }`}>
      <nav className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center group">
            <div className="relative h-10">
              <div className={`absolute inset-0 transition-opacity duration-300 ${
                scrollY > 50 || isMenuOpen ? 'opacity-100' : 'opacity-0'
              }`}>
                <LogoDark className="h-10 w-auto" />
              </div>
              <div className={`absolute inset-0 transition-opacity duration-300 ${
                scrollY > 50 || isMenuOpen ? 'opacity-0' : 'opacity-100'
              }`}>
                <LogoLight className="h-10 w-auto" />
              </div>
            </div>
          </Link>
            
          {/* Desktop Navigation */}
          <div className="hidden lg:flex space-x-8">
            {renderNavItems()}
            <a 
              href="https://app.filingreader.com/?feed=all&result=signup" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-secondary text-white px-6 py-2 rounded-lg font-medium transform transition-all duration-300 hover:bg-opacity-90 hover:scale-105 hover:shadow-md active:scale-95"
            >
              Sign Up
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="lg:hidden p-2 rounded-lg hover:bg-gray-100 transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className={`w-6 h-6 ${scrollY > 50 || isMenuOpen ? 'text-light-text' : 'text-white'}`} />
            ) : (
              <Menu className={`w-6 h-6 ${scrollY > 50 || isMenuOpen ? 'text-light-text' : 'text-white'}`} />
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        <div className={`lg:hidden transition-all duration-300 overflow-hidden ${
          isMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        }`}>
          <div className="py-4">
            {renderNavItems(true)}
            <div className="p-6 space-y-4">
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                }}
                className="block w-full text-center text-light-text-secondary hover:text-secondary transition-colors py-2"
              >
                Login
              </button>
              <a 
                href="https://app.filingreader.com/?feed=all&result=signup" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block w-full bg-secondary text-white px-6 py-3 rounded-lg font-medium text-center transition-all duration-300 hover:bg-opacity-90"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;