import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Key, 
  AlertCircle, 
  CheckCircle2, 
  ChevronLeft,
  Building2,
  AtSign,
  Globe,
  Briefcase,
  Radio
} from 'lucide-react';

const APIRegister = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    website: '',
    usageType: '',
    usageDescription: '',
    planType: 'free',
    website_url: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.company.trim()) newErrors.company = 'Company name is required';
    if (!formData.usageType) newErrors.usageType = 'Please select intended usage';
    if (!formData.usageDescription.trim()) {
      newErrors.usageDescription = 'Please describe how you plan to use the API';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      setIsSubmitting(true);
      setErrors({});

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api-key-request`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData)
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || data.details || 'Failed to submit request');
        }

        if (data.success) {
          setSubmitStatus({
            success: true,
            message: data.message || 'Your API key request has been submitted successfully. Please check your email for further instructions.'
          });

          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            website: '',
            usageType: '',
            usageDescription: '',
            planType: 'free'
          });
        } else {
          throw new Error(data.error || 'Submission failed');
        }
      } catch (error) {
        setSubmitStatus({
          success: false,
          message: `Error: ${error.message || 'An unexpected error occurred'}`
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen bg-light-bg-secondary">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="mb-8">
            <Link to="/api-docs" className="inline-flex items-center text-light-text-secondary hover:text-secondary transition-colors">
              <ChevronLeft className="w-4 h-4 mr-1" />
              Back to Documentation
            </Link>
          </div>

          <div className="bg-white rounded-lg border border-gray-200 p-8">
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold text-light-text mb-4">
                Request an API Key
              </h1>
              <p className="text-light-text-secondary">
                Fill out the form below to request access to the FilingReader API
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Hidden honeypot field */}
              <div style={{ display: 'none' }}>
                <input
                  type="text"
                  name="website_url"
                  value={formData.website_url}
                  onChange={handleInputChange}
                  tabIndex="-1"
                  aria-hidden="true"
                />
              </div>

              {/* Name fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-light-text font-medium mb-2" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 rounded-lg border ${
                      errors.firstName ? 'border-red-500' : 'border-gray-200'
                    } focus:outline-none focus:ring-2 focus:ring-secondary`}
                  />
                  {errors.firstName && (
                    <p className="mt-1 text-red-500 text-sm">{errors.firstName}</p>
                  )}
                </div>

                <div>
                  <label className="block text-light-text font-medium mb-2" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 rounded-lg border ${
                      errors.lastName ? 'border-red-500' : 'border-gray-200'
                    } focus:outline-none focus:ring-2 focus:ring-secondary`}
                  />
                  {errors.lastName && (
                    <p className="mt-1 text-red-500 text-sm">{errors.lastName}</p>
                  )}
                </div>
              </div>

              {/* Email field */}
              <div>
                <label className="block text-light-text font-medium mb-2" htmlFor="email">
                  <span className="flex items-center gap-2">
                    <AtSign className="w-4 h-4" />
                    Email Address
                  </span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 rounded-lg border ${
                    errors.email ? 'border-red-500' : 'border-gray-200'
                  } focus:outline-none focus:ring-2 focus:ring-secondary`}
                />
                {errors.email && (
                  <p className="mt-1 text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              {/* Company field */}
              <div>
                <label className="block text-light-text font-medium mb-2" htmlFor="company">
                  <span className="flex items-center gap-2">
                    <Building2 className="w-4 h-4" />
                    Company Name
                  </span>
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 rounded-lg border ${
                    errors.company ? 'border-red-500' : 'border-gray-200'
                  } focus:outline-none focus:ring-2 focus:ring-secondary`}
                />
                {errors.company && (
                  <p className="mt-1 text-red-500 text-sm">{errors.company}</p>
                )}
              </div>

              {/* Website field */}
              <div>
                <label className="block text-light-text font-medium mb-2" htmlFor="website">
                  <span className="flex items-center gap-2">
                    <Globe className="w-4 h-4" />
                    Company Website (Optional)
                  </span>
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  value={formData.website}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-secondary"
                  placeholder="https://"
                />
              </div>

              {/* Usage Type */}
              <div>
                <label className="block text-light-text font-medium mb-2">
                  <span className="flex items-center gap-2">
                    <Briefcase className="w-4 h-4" />
                    Intended Usage
                  </span>
                </label>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {[
                    'Research & Analysis',
                    'Trading Platform',
                    'Data Aggregation',
                    'Academic Research',
                    'Internal Tools',
                    'Other'
                  ].map((type) => (
                    <label key={type} className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="radio"
                        name="usageType"
                        value={type}
                        checked={formData.usageType === type}
                        onChange={handleInputChange}
                        className="text-secondary focus:ring-secondary"
                      />
                      <span className="text-light-text-secondary">{type}</span>
                    </label>
                  ))}
                </div>
                {errors.usageType && (
                  <p className="mt-1 text-red-500 text-sm">{errors.usageType}</p>
                )}
              </div>

              {/* Usage Description */}
              <div>
                <label className="block text-light-text font-medium mb-2" htmlFor="usageDescription">
                  <span className="flex items-center gap-2">
                    <Radio className="w-4 h-4" />
                    Describe Your API Usage
                  </span>
                </label>
                <textarea
                  id="usageDescription"
                  name="usageDescription"
                  value={formData.usageDescription}
                  onChange={handleInputChange}
                  rows={4}
                  className={`w-full px-4 py-2 rounded-lg border ${
                    errors.usageDescription ? 'border-red-500' : 'border-gray-200'
                  } focus:outline-none focus:ring-2 focus:ring-secondary`}
                  placeholder="Please describe how you plan to use the FilingReader API..."
                />
                {errors.usageDescription && (
                  <p className="mt-1 text-red-500 text-sm">{errors.usageDescription}</p>
                )}
              </div>

              {/* Plan Type */}
              <div>
                <label className="block text-light-text font-medium mb-2">
                  <span className="flex items-center gap-2">
                    <Key className="w-4 h-4" />
                    Plan Type
                  </span>
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {[
                    { id: 'free', name: 'Free', limit: '100 requests/hour' },
                    { id: 'pro', name: 'Pro', limit: '1,000 requests/hour' },
                    { id: 'enterprise', name: 'Enterprise', limit: 'Custom limits' }
                  ].map((plan) => (
                    <label
                      key={plan.id}
                      className={`
                        relative rounded-lg border p-4 cursor-pointer transition-all
                        ${formData.planType === plan.id
                          ? 'border-secondary bg-secondary bg-opacity-5'
                          : 'border-gray-200 hover:border-gray-300'
                        }
                      `}
                    >
                      <input
                        type="radio"
                        name="planType"
                        value={plan.id}
                        checked={formData.planType === plan.id}
                        onChange={handleInputChange}
                        className="sr-only"
                      />
                      <div>
                        <p className="font-medium text-light-text">{plan.name}</p>
                        <p className="text-sm text-light-text-secondary">{plan.limit}</p>
                      </div>
                    </label>
                  ))}
                </div>
              </div>

              {/* Submit Button and Status Message */}
              <div className="flex flex-col space-y-4">
                {submitStatus && (
                  <div className={`p-4 rounded-lg ${
                    submitStatus.success ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                  }`}>
                    <div className="flex items-center gap-2">
                      {submitStatus.success ? (
                        <CheckCircle2 className="w-5 h-5" />
                      ) : (
                        <AlertCircle className="w-5 h-5" />
                      )}
                      <p>{submitStatus.message}</p>
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`
                    bg-secondary text-white px-8 py-3 rounded-lg font-medium
                    transition-all duration-200 flex items-center justify-center gap-2
                    ${isSubmitting 
                      ? 'opacity-75 cursor-not-allowed' 
                      : 'hover:bg-opacity-90'
                    }
                  `}
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                      Processing...
                    </>
                  ) : (
                    <>
                      <Key className="w-5 h-5" />
                      Request API Key
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIRegister;