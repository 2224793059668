// FilingsSection.js
import React, { useState } from 'react';
import FilingsTable from '../components/charts/FilingsTable';
import WatchlistSelector from './watchlist/WatchlistSelector'
import { ListFilter } from 'lucide-react';


const FilingsSection = () => {
  // Set an initial value for selectedWatchlist
  const [selectedWatchlist, setSelectedWatchlist] = useState('Shanghai Top-30');
  const tableRef = React.useRef();

  const handleWatchlistChange = (watchlist) => {
    setSelectedWatchlist(watchlist);
    // Reset the table's page to 1 when changing watchlists
    if (tableRef.current?.resetPage) {
      tableRef.current.resetPage();
    }
  };

  return (
    <section className="bg-light-bg-secondary py-20">
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-3xl font-bold text-light-text">Latest Filings</h2>
            <div className="flex items-center text-light-text-secondary">
              <ListFilter className="w-5 h-5 mr-2" />
              <span>Filter by Sector</span>
            </div>
          </div>
          
          <WatchlistSelector 
            selectedWatchlist={selectedWatchlist}
            onWatchlistChange={handleWatchlistChange}
          />
        </div>

        {selectedWatchlist && (  // Only render table if we have a selected watchlist
          <FilingsTable 
            ref={tableRef}
            watchlist={selectedWatchlist} 
          />
        )}
      </div>
    </section>
  );
};

export default FilingsSection;