import React from 'react';
import { Search } from 'lucide-react';
import ChineseSpinningGlobe from '../../components/ui/ChineseSpinningGlobe';

const ChineseHeroSection = ({ 
  searchQuery, 
  handleSearchChange, 
  handleSearchFocus, 
  isLoading, 
  isDropdownOpen, 
  searchResults, 
  handleCompanyClick,
  searchRef 
}) => {
  return (
    <section className="relative min-h-[70vh] flex items-center pt-16 pb-16 overflow-visible bg-hero">
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-hero to-hero-darker opacity-80"></div>
      
      <div className="container mx-auto px-4 z-10">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="w-full md:w-1/2 max-w-xl mt-8 relative z-30">
            <h1 className="text-5xl md:text-7xl font-medium mb-8 animate-fadeIn text-white leading-relaxed tracking-tight">
              Chinese stock exchange filings in{' '}
              <span className="text-secondary">
                one place
              </span>
            </h1>
            <p className="text-xl font-light mb-10 animate-fadeIn animation-delay-200 text-gray-200 leading-relaxed">
              Access comprehensive filings from Shanghai, Shenzhen, and Hong Kong exchanges — 
              covering regulatory documents, annual reports, and corporate announcements.
            </p>
            <div ref={searchRef} className="relative animate-fadeIn animation-delay-400">
              <input
                type="text"
                placeholder="Search Chinese companies..."
                className="w-full py-4 px-6 pr-12 rounded-lg bg-hero-darker/80 backdrop-blur-sm border border-gray-700 text-white 
                         focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent
                         shadow-lg placeholder-gray-400 transition-all duration-300 ease-in-out
                         hover:border-gray-600"
                value={searchQuery}
                onChange={handleSearchChange}
                onFocus={handleSearchFocus}
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 transition-colors duration-300 ease-in-out" />
              {isLoading && (
                <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-secondary"></div>
                </div>
              )}
              {isDropdownOpen && searchResults.length > 0 && (
                <ul className="absolute z-10 w-full bg-hero-darker mt-2 rounded-lg shadow-xl 
                             max-h-60 overflow-y-auto border border-gray-700 custom-scrollbar
                             animate-fadeIn animation-delay-100">
                  {searchResults.map((result) => (
                    <li 
                      key={result.id} 
                      className="px-4 py-3 hover:bg-hero/50 cursor-pointer transition-all duration-200 ease-in-out 
                               border-b border-gray-700/50 last:border-b-0 group"
                      onClick={() => handleCompanyClick(result)}
                    >
                      <div className="flex items-center">
                        <div className="w-8 h-8 mr-3 flex items-center justify-center 
                                    transition-transform duration-200 ease-in-out group-hover:scale-110">
                          {result.flag_url ? (
                            <img src={result.flag_url} alt="Country flag" className="w-6 h-6" />
                          ) : (
                            <div className="w-6 h-6 bg-secondary rounded-full flex items-center justify-center
                                          shadow-lg shadow-secondary/20">
                              <span className="text-white font-medium text-xs">
                                {result.longNameEn.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div>
                          <p className="text-white font-medium group-hover:text-secondary transition-colors duration-200">
                            {result.longNameEn}
                          </p>
                          <p className="text-gray-400 text-sm group-hover:text-gray-300 transition-colors duration-200">
                            {result.stockCodes}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          
          <div className="w-full md:w-1/2 animate-fadeIn animation-delay-600">
            <ChineseSpinningGlobe />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChineseHeroSection;