import React, { useState, useEffect, useRef } from 'react';
import { FileText, BarChart, Sparkles, CircleDot } from 'lucide-react';
import BankOfChinaCharts from '../components/charts/BankOfChinaCharts'

const AIAnalysisSection = () => {
  const [analysisText, setAnalysisText] = useState('');
  const [showCharts, setShowCharts] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  
  const fullText = `This is an AI-generated analysis of the 2024 semi-annual financial statements and related notes for Bank of China Limited. Key points include:

• As of June 30, 2024, Bank of China's total assets were 33.91 trillion yuan, up 4.55% from the end of 2023.

• Net profit attributable to shareholders increased by 3.2% year-on-year to 112.43 billion yuan.

• The non-performing loan ratio decreased by 0.02 percentage points to 1.29% compared to the end of the previous year.

• The bank's capital adequacy ratio stood at 16.41%, maintaining a strong capital position.

• Overseas operations contributed 16.8% of the group's pre-tax profit, showing continued international growth.

This analysis suggests that Bank of China has maintained steady growth and improved asset quality in the first half of 2024, despite global economic challenges.`;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let index = 0;
      const textIntervalId = setInterval(() => {
        if (index < fullText.length) {
          setAnalysisText((prev) => fullText.slice(0, index + 1));
          index++;
        } else {
          clearInterval(textIntervalId);
        }
      }, 10);

      return () => clearInterval(textIntervalId);
    }
  }, [isVisible]);

  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.startsWith('•') ? (
          <li className="flex items-start gap-2 mb-4">
            <CircleDot className="w-5 h-5 text-secondary flex-shrink-0 mt-0.5" />
            <span className="text-light-text-secondary">{line.substr(1).trim()}</span>
          </li>
        ) : (
          <p className="mb-4 text-light-text-secondary leading-relaxed">{line}</p>
        )}
      </React.Fragment>
    ));
  };

  const toggleView = () => {
    setShowCharts((prev) => !prev);
  };

  return (
    <section className="bg-gradient-to-b from-light-bg-secondary to-white py-24" ref={sectionRef}>
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-light-text tracking-tight">
            AI-Powered Filing Analysis
          </h2>
          <p className="text-xl text-light-text-secondary max-w-2xl mx-auto">
            Instantly extract insights from complex financial documents
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* PDF Viewer */}
          <div className="bg-white rounded-xl border border-gray-200 shadow-lg overflow-hidden">
            <div className="border-b border-gray-100 p-4">
              <div className="flex items-center gap-2">
                <div className="w-3 h-3 rounded-full bg-red-500"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                <div className="w-3 h-3 rounded-full bg-green-500"></div>
              </div>
            </div>
            <div className="aspect-w-16 aspect-h-9" style={{ height: '600px' }}>
              <iframe
                src="/Bank_of_China_semi_annual_report.pdf"
                title="Sample PDF Viewer"
                className="w-full h-full"
              />
            </div>
          </div>

          {/* Analysis Panel */}
          <div className="bg-white rounded-xl border border-gray-200 shadow-lg overflow-hidden">
            <div className="flex items-center justify-between p-4 border-b border-gray-100">
              <div className="flex items-center gap-3">
                {showCharts ? (
                  <>
                    <div className="p-2 bg-secondary/10 rounded-lg">
                      <BarChart className="w-5 h-5 text-secondary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-light-text">Visual Insights</h3>
                      <p className="text-sm text-light-text-secondary">Interactive data visualization</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-2 bg-secondary/10 rounded-lg">
                      <Sparkles className="w-5 h-5 text-secondary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-light-text">AI Summary</h3>
                      <p className="text-sm text-light-text-secondary">Key points and analysis</p>
                    </div>
                  </>
                )}
              </div>
              
              <button
                onClick={toggleView}
                className="flex items-center gap-2 bg-secondary text-white px-4 py-2 rounded-lg 
                         hover:bg-opacity-90 transition-all duration-200 shadow-lg shadow-secondary/20 
                         hover:shadow-secondary/30"
              >
                {showCharts ? (
                  <>
                    <FileText className="w-4 h-4" />
                    View Summary
                  </>
                ) : (
                  <>
                    <BarChart className="w-4 h-4" />
                    View Charts
                  </>
                )}
              </button>
            </div>

            <div className="p-6 h-[600px] overflow-y-auto">
              {showCharts ? (
                <BankOfChinaCharts />
              ) : (
                <div className="prose prose-lg max-w-none">
                  {formatText(analysisText)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIAnalysisSection;