// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import analytics from './services/analytics';
import useAnalytics from './hooks/useAnalytics';
import LandingPage from './pages/LandingPage';
import AboutPage from './pages/AboutPage';
import APIDocumentation from './features/api/APIDocumentation';
import APIRegister from './features/api/APIRegister';
import ChineseExchangesPage from './pages/ChineseExchangesPage';
import NotFoundPage from './pages/NotFoundPage';
import NewsWirePage from './pages/NewsWirePage';
import NewsStory from './components/news/NewsStory';


// Performance observer for core web vitals
const reportWebVitals = ({ name, delta, id }) => {
  analytics.trackPerformance(name, delta);
};

function App() {
  // Initialize analytics
  useEffect(() => {
    analytics.init();
    
    // Track initial load performance
    if ('performance' in window) {
      window.performance.getEntriesByType('navigation').forEach(entry => {
        analytics.trackPerformance('navigationTiming', {
          dnsLookup: entry.domainLookupEnd - entry.domainLookupStart,
          tcpConnection: entry.connectEnd - entry.connectStart,
          timeToFirstByte: entry.responseStart - entry.requestStart,
          domInteractive: entry.domInteractive,
          domComplete: entry.domComplete,
        });
      });
    }
  }, []);

  // Handle errors globally
  useEffect(() => {
    const handleError = (event) => {
      analytics.trackError(
        'JavaScript Error',
        event.error?.message || 'Unknown error',
        {
          stack: event.error?.stack,
          filename: event.filename,
          lineno: event.lineno,
          colno: event.colno
        }
      );
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  // Track network status changes
  useEffect(() => {
    const handleOnline = () => analytics.trackCustomEvent('Network', 'Online');
    const handleOffline = () => analytics.trackCustomEvent('Network', 'Offline');

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

// Separate component to use router hooks
function AppContent() {
  useAnalytics(); // Use our custom analytics hook

  // Track 404 errors
  useEffect(() => {
    const track404 = () => {
      if (document.title.includes('404')) {
        analytics.trackError('404', window.location.pathname);
      }
    };

    // Small delay to ensure title is updated
    const timeoutId = setTimeout(track404, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  // Get current path and construct canonical URL
  const currentPath = window.location.pathname;
  const canonicalUrl = `https://filingreader.com${currentPath === '/' ? '' : currentPath}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/news-wire" element={<NewsWirePage />} />
        <Route path="/news-wire/:exchange/:date/:slug" element={<NewsStory />} />
        <Route path="/chinese-exchanges" element={<ChineseExchangesPage />} />
        <Route path="/api-docs" element={<APIDocumentation />} />
        <Route path="/api-register" element={<APIRegister />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export { reportWebVitals };
export default App;