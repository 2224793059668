import React, { useState, useEffect, useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import MetaTags from '../components/utils/MetaTags';
import Navbar from '../layouts/Navbar';
import ChineseHeroSection from '../features/hero/ChineseHeroSection';
import FilingsSection from '../features/FilingsSection';
import ExchangeInfoSection from '../components/ExchangeInfoSection';
import Footer from '../components/Footer';
import useAnalytics from '../hooks/useAnalytics';

const ChineseExchangesPage = () => {
  const analytics = useAnalytics();
  const searchRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

  // Track user engagement time
  useEffect(() => {
    const interval = setInterval(() => {
      const engagementTime = Date.now() - lastInteractionTime;
      analytics.trackCustomEvent('Engagement', 'Time on Page', null, engagementTime / 1000);
    }, 30000); // Track every 30 seconds

    return () => clearInterval(interval);
  }, [lastInteractionTime, analytics]);

  // Handle scroll tracking
  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setScrollY(newScrollY);
      setLastInteractionTime(Date.now());

      // Track scroll depth
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (newScrollY / height) * 100;
      
      if (scrollPercentage >= 25 && scrollPercentage < 50) {
        analytics.trackCustomEvent('Scroll', 'Reached 25%');
      } else if (scrollPercentage >= 50 && scrollPercentage < 75) {
        analytics.trackCustomEvent('Scroll', 'Reached 50%');
      } else if (scrollPercentage >= 75 && scrollPercentage < 90) {
        analytics.trackCustomEvent('Scroll', 'Reached 75%');
      } else if (scrollPercentage >= 90) {
        analytics.trackCustomEvent('Scroll', 'Reached 90%');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [analytics]);

  useEffect(() => {
    if (searchQuery.length > 2) {
      setIsLoading(true);
      setIsDropdownOpen(true);
      
      const startTime = Date.now();
      analytics.trackUserAction('Search', 'Query Entered', searchQuery);

      fetch(`${process.env.REACT_APP_API_URL}/search?query=${encodeURIComponent(searchQuery)}&exchanges=SSE,SZSE,HKEX`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data);
          setIsLoading(false);
          
          const duration = Date.now() - startTime;
          analytics.trackUserAction('Search', 'Results Received', searchQuery, {
            resultCount: data.length,
            duration: duration,
            exchanges: 'Chinese'
          });
        })
        .catch(error => {
          setIsLoading(false);
          analytics.trackError('Search Error', error.message, {
            query: searchQuery,
            exchanges: 'Chinese',
            timestamp: new Date().toISOString()
          });
        });
    } else {
      setSearchResults([]);
      setIsDropdownOpen(false);
    }
  }, [searchQuery, analytics]);

  // Track section visibility
  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          analytics.trackUserAction('Section View', 'Section Visible', entry.target.id);
          observer.unobserve(entry.target); // Only track first view
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2 // 20% visibility required
    });

    ['exchange-info', 'filings', 'cta-section'].forEach(sectionId => {
      const element = document.getElementById(sectionId);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [analytics]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setLastInteractionTime(Date.now());
  };

  const handleSearchFocus = () => {
    analytics.trackUserAction('Search', 'Focus', 'Chinese Exchanges');
    setLastInteractionTime(Date.now());
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
    }
  };

  const handleCompanyClick = (company) => {
    analytics.trackConversion('Company Selected', {
      company: company.longNameEn,
      stockCode: company.stockCodes,
      source: 'chinese_exchanges_search'
    });

    const stockCode = company.stockCodes.split(',')[0].trim();
    window.open(
      `https://app.filingreader.com/?feed=${encodeURIComponent(stockCode)}&company=${encodeURIComponent(company.longNameEn)}`,
      '_blank'
    );
  };

  return (
    <div className="font-sans text-light-text min-h-screen bg-hero-darker">
      <MetaTags 
        page="chinese-exchanges"
        customMeta={{
          title: "Chinese Stock Exchanges | SSE, SZSE & HKEX Filings | FilingReader",
          description: "Access real-time filings from Shanghai, Shenzhen, and Hong Kong stock exchanges. Comprehensive coverage of Chinese market regulatory filings and financial reports."
        }}
      />
      
      <Navbar scrollY={scrollY} />

      <main>
        <ChineseHeroSection
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          handleSearchFocus={handleSearchFocus}
          isLoading={isLoading}
          isDropdownOpen={isDropdownOpen}
          searchResults={searchResults}
          handleCompanyClick={handleCompanyClick}
          searchRef={searchRef}
        />

        {/* Exchange Information Section */}
        <section id="exchange-info">
          <ExchangeInfoSection />
        </section>

        {/* Updated Filings Section */}
        <section id="filings">
          <FilingsSection />
        </section>

        {/* Call to Action */}
        <section id="cta-section" className="bg-light-bg py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6 text-light-text">
                Monitor Chinese Markets by Sector
              </h2>
              <p className="text-xl text-light-text-secondary mb-8">
                Track filings from specific industries and sectors with real-time updates 
                and comprehensive coverage of Chinese markets.
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <a 
                  href="https://app.filingreader.com/?feed=all&result=signup&form=signup" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-8 py-3 rounded-lg bg-secondary text-white hover:bg-opacity-90 transition-all"
                  onClick={() => analytics.trackConversion('CTA Click', { 
                    action: 'Start Free Trial',
                    source: 'chinese_exchanges_page'
                  })}
                >
                  Start Free Trial
                  <ArrowRight className="ml-2 w-5 h-5" />
                </a>
                <a 
                  href="https://app.filingreader.com/?page=plan" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center justify-center px-8 py-3 rounded-lg bg-primary-light text-light-text hover:bg-gray-100 transition-all"
                  onClick={() => analytics.trackConversion('CTA Click', { 
                    action: 'View Pricing',
                    source: 'chinese_exchanges_page'
                  })}
                >
                  View Pricing
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer analytics={analytics} />
    </div>
  );
};

export default ChineseExchangesPage;