import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Copy, Check, Terminal, Globe } from 'lucide-react';

const APIDocumentation = () => {
  const [expandedSection, setExpandedSection] = useState('authentication');
  const [copiedEndpoint, setCopiedEndpoint] = useState(null);

  const handleCopyCode = (code, endpoint) => {
    navigator.clipboard.writeText(code);
    setCopiedEndpoint(endpoint);
    setTimeout(() => setCopiedEndpoint(null), 2000);
  };

  const CodeBlock = ({ code, language = 'bash', endpoint }) => (
    <div className="relative mt-4 rounded-lg overflow-hidden bg-primary-light">
      <div className="p-4 font-mono text-sm text-light-text overflow-x-auto">
        <pre className="whitespace-pre-wrap">{code}</pre>
      </div>
      <button
        onClick={() => handleCopyCode(code, endpoint)}
        className="absolute top-3 right-3 p-2 rounded-lg bg-white hover:bg-gray-100 transition-colors"
      >
        {copiedEndpoint === endpoint ? (
          <Check className="w-4 h-4 text-secondary" />
        ) : (
          <Copy className="w-4 h-4 text-light-text-secondary" />
        )}
      </button>
    </div>
  );

  const Section = ({ id, title, children }) => (
    <div className="mb-8 bg-white p-6 rounded-lg border border-gray-200">
      <button
        onClick={() => setExpandedSection(expandedSection === id ? null : id)}
        className="w-full flex items-center justify-between text-left"
      >
        <h2 className="text-2xl font-bold text-light-text">{title}</h2>
        {expandedSection === id ? (
          <ChevronUp className="w-6 h-6 text-light-text-secondary" />
        ) : (
          <ChevronDown className="w-6 h-6 text-light-text-secondary" />
        )}
      </button>
      {expandedSection === id && (
        <div className="mt-4 space-y-4">{children}</div>
      )}
    </div>
  );

  const Endpoint = ({ method, path, description, parameters, response }) => (
    <div className="border border-gray-200 rounded-lg p-6 mb-6 bg-light-bg-secondary">
      <div className="flex items-start gap-4">
        <span className={`px-3 py-1 rounded-full text-sm font-medium ${
          method === 'GET' ? 'bg-secondary bg-opacity-10 text-secondary' :
          method === 'POST' ? 'bg-primary bg-opacity-10 text-primary' :
          method === 'PUT' ? 'bg-yellow-100 text-yellow-800' :
          'bg-red-100 text-red-800'
        }`}>
          {method}
        </span>
        <code className="flex-1 text-sm bg-white px-3 py-1 rounded text-light-text">{path}</code>
      </div>
      <p className="mt-4 text-light-text-secondary">{description}</p>
      
      {parameters && (
        <div className="mt-4">
          <h4 className="font-medium text-light-text mb-2">Parameters:</h4>
          <div className="bg-white rounded-lg p-4">
            {parameters.map((param, index) => (
              <div key={index} className="mb-2 last:mb-0">
                <code className="text-sm font-medium text-secondary">{param.name}</code>
                <span className="text-sm text-light-text-secondary ml-2">({param.type})</span>
                {param.required && <span className="text-secondary ml-2">required</span>}
                <p className="text-sm text-light-text-secondary mt-1">{param.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {response && (
        <div className="mt-4">
          <h4 className="font-medium text-light-text mb-2">Example Response:</h4>
          <CodeBlock 
            code={JSON.stringify(response, null, 2)}
            language="json"
            endpoint={path}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className="bg-light-bg-secondary min-h-screen">
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-light-text">
              API Documentation
            </h1>
            <p className="text-xl text-light-text-secondary">
              Power your applications with FilingReader's comprehensive API
            </p>
          </div>

          <div className="bg-white p-6 rounded-lg border border-gray-200 mb-8">
            <div className="flex items-center gap-2 text-light-text-secondary">
              <Globe className="w-5 h-5 text-secondary" />
              Base URL: <code className="bg-primary-light px-2 py-1 rounded">https://api.filingreader.com/v1</code>
            </div>
          </div>

          <Section id="authentication" title="Authentication">
            <p className="text-light-text-secondary mb-4">
              All API requests require authentication using an API key. Include your API key in the
              Authorization header of each request.
            </p>
            <CodeBlock
              code="curl -H 'Authorization: Bearer your_api_key_here' \
     https://api.filingreader.com/v1/filings"
              endpoint="authentication"
            />
          </Section>

          <Section id="endpoints" title="Endpoints">
            <Endpoint
              method="GET"
              path="/filings"
              description="Retrieve a list of filings based on specified criteria."
              parameters={[
                {
                  name: "company",
                  type: "string",
                  description: "Company stock code or name",
                  required: false
                },
                {
                  name: "exchange",
                  type: "string",
                  description: "Stock exchange identifier (e.g., SSE, HKEX)",
                  required: false
                },
                {
                  name: "from",
                  type: "string",
                  description: "Start date (YYYY-MM-DD)",
                  required: false
                },
                {
                  name: "to",
                  type: "string",
                  description: "End date (YYYY-MM-DD)",
                  required: false
                }
              ]}
              response={{
                "status": "success",
                "data": [
                  {
                    "id": "f12345",
                    "company": "Bank of China",
                    "stockCode": "601988.SS",
                    "exchange": "SSE",
                    "title": "2024 Semi-Annual Report",
                    "filingDate": "2024-08-30",
                    "url": "https://api.filingreader.com/v1/filings/f12345/download",
                    "language": "zh-CN"
                  }
                ],
                "pagination": {
                  "currentPage": 1,
                  "totalPages": 5,
                  "totalResults": 42
                }
              }}
            />

            <Endpoint
              method="GET"
              path="/companies"
              description="Search for companies across all supported exchanges."
              parameters={[
                {
                  name: "q",
                  type: "string",
                  description: "Search query (company name or stock code)",
                  required: false
                },
                {
                  name: "exchange",
                  type: "string",
                  description: "Filter by stock exchange",
                  required: false
                }
              ]}
              response={{
                "status": "success",
                "data": [
                  {
                    "id": "c789",
                    "name": "Bank of China",
                    "stockCodes": ["601988.SS", "3988.HK"],
                    "exchanges": ["SSE", "HKEX"],
                    "country": "CN",
                    "sector": "Financial Services"
                  }
                ]
              }}
            />
          </Section>

          <Section id="rate-limits" title="Rate Limits">
            <div className="space-y-4">
              <p className="text-light-text-secondary">
                API requests are subject to rate limiting based on your subscription tier:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  { tier: "Free", limit: "100 requests/hour" },
                  { tier: "Pro", limit: "1,000 requests/hour" },
                  { tier: "Enterprise", limit: "Custom limits" }
                ].map((plan) => (
                  <div key={plan.tier} className="bg-light-bg-secondary p-4 rounded-lg border border-gray-200">
                    <h3 className="font-semibold text-light-text mb-2">{plan.tier}</h3>
                    <p className="text-light-text-secondary">{plan.limit}</p>
                  </div>
                ))}
              </div>
              <p className="text-light-text-secondary mt-4">
                Rate limit information is included in the response headers:
              </p>
              <CodeBlock
                code={`X-RateLimit-Limit: 1000
X-RateLimit-Remaining: 986
X-RateLimit-Reset: 1635789600`}
                endpoint="rate-limits"
              />
            </div>
          </Section>

          <Section id="errors" title="Error Handling">
            <div className="space-y-4">
              <p className="text-light-text-secondary">
                The API uses conventional HTTP response codes to indicate the success or failure of requests:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {[
                  { code: "200", desc: "Success" },
                  { code: "400", desc: "Bad Request" },
                  { code: "401", desc: "Unauthorized" },
                  { code: "403", desc: "Forbidden" },
                  { code: "404", desc: "Not Found" },
                  { code: "429", desc: "Too Many Requests" }
                ].map((error) => (
                  <div key={error.code} className="flex items-center gap-3 bg-light-bg-secondary p-3 rounded-lg">
                    <code className="bg-primary-light px-2 py-1 rounded text-light-text">{error.code}</code>
                    <span className="text-light-text-secondary">{error.desc}</span>
                  </div>
                ))}
              </div>
              <CodeBlock
                code={JSON.stringify({
                  "status": "error",
                  "code": "rate_limit_exceeded",
                  "message": "API rate limit exceeded",
                  "details": {
                    "resetTime": "2024-03-24T15:30:00Z",
                    "limitPeriod": "1 hour"
                  }
                }, null, 2)}
                language="json"
                endpoint="errors"
              />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default APIDocumentation;