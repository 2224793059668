// hooks/useAnalytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../services/analytics';

export const useAnalytics = () => {
  const location = useLocation();

  // Track page views automatically
  useEffect(() => {
    analytics.trackPageView(location.pathname + location.search);
  }, [location]);

  // Track session duration
  useEffect(() => {
    const sessionStart = Date.now();
    analytics.trackSessionStart();

    return () => {
      const duration = Date.now() - sessionStart;
      analytics.trackSessionEnd(duration);
    };
  }, []);

  return analytics;
};

export default useAnalytics;