import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import { ArrowUpCircle, ArrowDownCircle } from 'lucide-react';

const BankOfChinaCharts = () => {
  const assetsData = [
    { year: '2023 Year-End', assets: 32.43, actualValue: 'RMB 32.43 trillion' },
    { year: '2024 Mid-Year', assets: 33.91, actualValue: 'RMB 33.91 trillion' },
  ];

  const profitData = [
    { year: '2023 H1', profit: 127.69, actualValue: 'RMB 127.69 billion' },
    { year: '2024 H1', profit: 126.54, actualValue: 'RMB 126.54 billion' },
  ];

  const calculateChange = (data, key) => {
    if (data.length < 2) return { value: 0, isPositive: true };
    const change = ((data[1][key] - data[0][key]) / data[0][key]) * 100;
    return {
      value: Math.abs(change).toFixed(2),
      isPositive: change >= 0,
    };
  };

  const assetsChange = calculateChange(assetsData, 'assets');
  const profitChange = calculateChange(profitData, 'profit');

  const formatTooltipValue = (value, name, props) => {
    if (props.payload.actualValue) {
      return props.payload.actualValue;
    }
    return value.toFixed(2);
  };

  const ChartCard = ({ title, data, dataKey, height = 300, color, change }) => (
    <div className="bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden transition-shadow hover:shadow-md">
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl font-semibold text-light-text">{title}</h3>
          <div className={`flex items-center gap-1 text-sm font-medium ${
            change.isPositive ? 'text-green-600' : 'text-red-600'
          }`}>
            {change.isPositive ? (
              <ArrowUpCircle className="w-4 h-4" />
            ) : (
              <ArrowDownCircle className="w-4 h-4" />
            )}
            {change.value}%
          </div>
        </div>
        <p className="text-light-text-secondary text-sm">
          Current: {data[data.length - 1].actualValue}
        </p>
      </div>
      
      <div className="p-6">
        <ResponsiveContainer width="100%" height={height}>
          <BarChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis 
              dataKey="year" 
              stroke="#4b5563" 
              fontSize={12}
              tickLine={false}
            />
            <YAxis 
              stroke="#4b5563" 
              fontSize={12}
              tickLine={false}
              axisLine={false}
              tickFormatter={(value) => `${value}${dataKey === 'assets' ? 'T' : 'B'}`}
            />
            <Tooltip 
              formatter={formatTooltipValue}
              contentStyle={{ 
                backgroundColor: '#fff',
                border: '1px solid #e5e7eb',
                borderRadius: '0.5rem',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
              cursor={{ fill: '#f3f4f6' }}
            />
            <Bar 
              dataKey={dataKey} 
              fill={color}
              radius={[4, 4, 0, 0]}
              barSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <ChartCard 
        title="Total Assets"
        data={assetsData}
        dataKey="assets"
        color="#64E9B1"
        change={assetsChange}
      />
      
      <ChartCard 
        title="Net Profit"
        data={profitData}
        dataKey="profit"
        color="#FF9776"
        change={profitChange}
      />
    </div>
  );
};

export default BankOfChinaCharts;