// components/ui/badge.js
import React from "react";

const Badge = React.forwardRef(({
  className = "",
  variant = "default",
  children,
  ...props
}, ref) => {
  const baseStyles = "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2";
  
  const variants = {
    default: "border-transparent bg-gray-900 text-gray-50",
    secondary: "border-transparent bg-secondary text-secondary-foreground",
    outline: "text-foreground",
  };

  const classes = [
    baseStyles,
    variants[variant],
    className
  ].join(" ");

  return (
    <div
      className={classes}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

Badge.displayName = "Badge";

export { Badge };