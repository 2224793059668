import React from 'react';
import { Link } from 'react-router-dom';
import { Code, Database, Key, Copy, Terminal } from 'lucide-react';

const APISection = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // Could add toast notification here
  };

  return (
    <section id="api" className="relative bg-gradient-to-b from-light-bg via-light-bg to-white py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-light-text tracking-tight">
            Power Your Applications with Our API
          </h2>
          <p className="text-xl text-light-text-secondary max-w-2xl mx-auto">
            Access millions of filings programmatically through our developer-friendly API
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <div className="space-y-6">
              <h3 className="text-2xl font-semibold text-secondary tracking-tight">
                Access Over 10 Million Company Filings
              </h3>
              <p className="text-light-text-secondary text-lg leading-relaxed">
                Integrate FilingReader's extensive database directly into your applications, 
                tools, or workflows. Our RESTful API provides programmatic access to our 
                entire collection of global company filings.
              </p>
            </div>

            <div className="space-y-6">
              {[
                { 
                  icon: <Database className="w-6 h-6" />, 
                  title: "Comprehensive Coverage",
                  text: "Access filings from all major global stock exchanges" 
                },
                { 
                  icon: <Code className="w-6 h-6" />, 
                  title: "Developer Friendly",
                  text: "RESTful API with detailed documentation and examples" 
                },
                { 
                  icon: <Key className="w-6 h-6" />, 
                  title: "Secure Access",
                  text: "Enterprise-grade security with flexible rate limits" 
                }
              ].map((item, index) => (
                <div 
                  key={index} 
                  className="flex items-start space-x-4 p-4 rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow duration-200"
                >
                  <div className="p-2 bg-secondary/10 rounded-lg text-secondary">
                    {item.icon}
                  </div>
                  <div>
                    <h4 className="font-semibold text-light-text mb-1">{item.title}</h4>
                    <p className="text-light-text-secondary">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap gap-4">
              <Link 
                to="/api-docs" 
                className="inline-flex items-center gap-2 bg-secondary text-white font-medium py-3 px-6 rounded-lg
                         hover:bg-opacity-90 transition-all duration-200 shadow-lg shadow-secondary/20 hover:shadow-secondary/30"
              >
                <Terminal className="w-5 h-5" />
                Explore API Documentation
              </Link>
              <Link 
                to="/api-register" 
                className="inline-flex items-center gap-2 bg-white text-gray-800 font-medium py-3 px-6 rounded-lg
                         border border-gray-200 hover:border-gray-300 transition-all duration-200 shadow-sm hover:shadow-md"
              >
                <Key className="w-5 h-5" />
                Get API Key
              </Link>
            </div>
          </div>

          <div className="relative group">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/30 to-secondary/30 opacity-0 group-hover:opacity-100 
                          transition-opacity duration-300 rounded-xl blur-xl">
            </div>
            <div className="relative bg-white rounded-xl shadow-xl border border-gray-100">
              <div className="flex items-center justify-between p-4 border-b border-gray-100">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <button 
                  onClick={() => copyToClipboard(`import requests

API_KEY = 'your_api_key_here'
BASE_URL = 'https://api.filingreader.com/v1'

# Get the latest 10-K filing for Apple Inc.
response = requests.get(
    f'{BASE_URL}/filings',
    params={
        'company': 'AAPL',
        'type': '10-K',
        'limit': 1
    },
    headers={'Authorization': f'Bearer {API_KEY}'}
)

if response.status_code == 200:
    filing = response.json()['data'][0]
    print(f"Latest 10-K for Apple Inc:")
    print(f"Filed on: {filing['date']}")
    print(f"Download URL: {filing['url']}")
`)} 
                  className="flex items-center gap-1 text-sm text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <Copy className="w-4 h-4" />
                  Copy code
                </button>
              </div>
              <div className="p-6 overflow-x-auto">
                <pre className="text-sm text-gray-800">
                  <code>{`import requests

API_KEY = 'your_api_key_here'
BASE_URL = 'https://api.filingreader.com/v1'

# Get the latest 10-K filing for Apple Inc.
response = requests.get(
    f'{BASE_URL}/filings',
    params={
        'company': 'AAPL',
        'type': '10-K',
        'limit': 1
    },
    headers={'Authorization': f'Bearer {API_KEY}'}
)

if response.status_code == 200:
    filing = response.json()['data'][0]
    print(f"Latest 10-K for Apple Inc:")
    print(f"Filed on: {filing['date']}")
    print(f"Download URL: {filing['url']}")
                  `}</code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default APISection;