import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Search, ArrowLeft } from 'lucide-react';
import { ReactComponent as Logo } from '../assets/filingreader-logo.svg';

const NotFoundPage = () => {
  return (
    <div className="min-h-screen bg-light-bg-secondary flex flex-col">
      <div className="flex-grow flex items-center justify-center p-4">
        <div className="max-w-2xl w-full text-center">
          {/* Logo */}
          <Link to="/" className="inline-block mb-8">
            <Logo className="h-12 w-auto mx-auto" />
          </Link>

          {/* 404 Message */}
          <h1 className="text-8xl font-bold text-secondary mb-4">404</h1>
          <h2 className="text-3xl font-bold text-light-text mb-6">Page Not Found</h2>
          <p className="text-lg text-light-text-secondary mb-8">
            The page you're looking for doesn't exist or has been moved.
            Let's get you back on track.
          </p>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link 
              to="/" 
              className="inline-flex items-center justify-center gap-2 bg-secondary text-white px-6 py-3 rounded-lg hover:bg-opacity-90 transition-all"
            >
              <Home className="w-5 h-5" />
              Back to Home
            </Link>
            <a 
              href="https://app.filingreader.com"
              className="inline-flex items-center justify-center gap-2 bg-primary-light text-light-text px-6 py-3 rounded-lg hover:bg-gray-100 transition-all"
            >
              <Search className="w-5 h-5" />
              Search Filings
            </a>
          </div>

          {/* Help Links */}
          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {[
              { text: "About FilingReader", href: "/about" },
              { text: "API Documentation", href: "/api-docs" },
              { text: "Contact Support", href: "mailto:info@filingreader.com" }
            ].map((link, index) => (
              <a
                key={index}
                href={link.href}
                className="text-light-text-secondary hover:text-secondary transition-colors"
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="py-6 text-center text-light-text-tertiary">
        <p>&copy; 2024 FilingReader. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default NotFoundPage;