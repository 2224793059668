import React from 'react';
import { Search } from 'lucide-react';

const NewsHeader = ({ searchQuery, onSearchChange }) => {
  return (
    <section className="relative min-h-[50vh] flex items-center pt-16 pb-16 overflow-visible bg-hero">
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-hero to-hero-darker opacity-80"></div>
      
      {/* Content */}
      <div className="container mx-auto px-4 z-10">
        <div className="max-w-3xl relative z-30">
          {/* Title Section */}
          <h1 className="text-5xl md:text-7xl font-medium mb-8 animate-fadeIn text-white leading-relaxed tracking-tight">
            FilingReader{' '}
            <span className="text-secondary">
              News Wire
            </span>
          </h1>
          
          <p className="text-xl font-light mb-10 animate-fadeIn animation-delay-200 text-gray-200 leading-relaxed">
            AI-powered news coverage of important company announcements and filings from Asian markets
          </p>
          
          {/* Search Bar */}
          <div className="relative animate-fadeIn animation-delay-400">
            <input
              type="text"
              placeholder="Search news stories..."
              value={searchQuery}
              onChange={onSearchChange}
              className="w-full py-4 px-6 pr-12 rounded-lg bg-hero-darker/80 backdrop-blur-sm border border-gray-700 
                       text-white placeholder-gray-400
                       focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent
                       shadow-lg transition-all duration-300 ease-in-out
                       hover:border-gray-600"
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 transition-colors duration-300 ease-in-out" />
          </div>

          {/* Trending Topics */}
          <div className="mt-6 flex flex-wrap gap-3 animate-fadeIn animation-delay-600">
            <span className="text-gray-300">Trending:</span>
            <button className="text-secondary hover:text-secondary/80 transition-colors">
              Earnings Reports
            </button>
            <span className="text-gray-500">·</span>
            <button className="text-secondary hover:text-secondary/80 transition-colors">
              Regulatory Updates
            </button>
            <span className="text-gray-500">·</span>
            <button className="text-secondary hover:text-secondary/80 transition-colors">
              Market Analysis
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsHeader;