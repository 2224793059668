import React, { useState, useEffect } from 'react';
import { Globe, FileText, Clock, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import Navbar from '../layouts/Navbar';
import MetaTags from '../components/utils/MetaTags';

const AboutPage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stats = [
    { icon: <Globe className="w-8 h-8 text-secondary" />, value: '8+', label: 'Stock Exchanges' },
    { icon: <FileText className="w-8 h-8 text-secondary" />, value: '1M+', label: 'Filings Processed' },
    { icon: <Clock className="w-8 h-8 text-secondary" />, value: '24/7', label: 'Real-time Updates' },
    { icon: <Users className="w-8 h-8 text-secondary" />, value: '10k+', label: 'Active Users' },
  ];

  return (
    <div className="min-h-screen bg-light-bg text-light-text">
      {/* Navbar */}
      <MetaTags page="about" />
      <Navbar scrollY={scrollY} />

      {/* Hero Section with Background Image */}
      <section 
        className="relative min-h-[50vh] flex items-center pt-20"
        style={{
          backgroundImage: 'url("/images/hero-background.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        {/* White overlay with high opacity */}
        <div className="absolute inset-0 bg-white bg-opacity-90"></div>
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-primary">About FilingReader</h1>
            <p className="text-xl text-light-text-secondary">
              Empowering global financial decisions with comprehensive access to market intelligence
            </p>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <main className="relative">
        {/* Story Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold mb-8 text-light-text">Our Story</h2>
              <div className="space-y-6 text-lg text-light-text-secondary">
                <p>
                  FilingReader aggregates and organizes stock exchange filings from around the world, 
                  making it easier to access key financial information. The platform provides timely, 
                  accurate, and comprehensive access to financial reports, annual statements, 
                  prospectuses, and other regulatory filings.
                </p>
                <p>
                  Launched in 2021, FilingReader began providing data on Chinese stock exchanges, 
                  but has since expanded to eight exchanges primarily in the Asia-Pacific region.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="bg-light-bg-secondary py-20 border-y border-gray-200">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {stats.map((stat, index) => (
                <div key={index} className="text-center bg-white p-6 rounded-lg border border-gray-200">
                  <div className="inline-block mb-4">{stat.icon}</div>
                  <p className="text-4xl font-bold mb-2 text-light-text">{stat.value}</p>
                  <p className="text-light-text-secondary">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Mission Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold mb-8 text-light-text">Our Mission</h2>
              <p className="text-lg text-light-text-secondary">
                At FilingReader, our mission is to democratize access to global financial information. 
                We believe that by providing easy access to comprehensive and up-to-date financial 
                filings, we can empower investors, analysts, and financial professionals to make more 
                informed decisions.
              </p>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-light-bg-secondary py-20 border-y border-gray-200">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold mb-8 text-light-text">Key Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {[
                  "Real-time access to filings from major Asian stock exchanges",
                  "Advanced search and filtering capabilities",
                  "AI-powered analysis and insights",
                  "Customizable alerts and notifications",
                  "Multi-language support for global users"
                ].map((feature, index) => (
                  <div key={index} className="flex items-start space-x-3">
                    <div className="w-2 h-2 bg-secondary rounded-full mt-2 flex-shrink-0"></div>
                    <p className="text-lg text-light-text-secondary">{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6 text-light-text">Contact Us</h2>
              <p className="text-lg text-light-text-secondary mb-8">
                Have questions or need support? We're here to help!
              </p>
              <a 
                href="mailto:info@filingreader.com" 
                className="inline-block bg-secondary text-white px-8 py-3 rounded-lg hover:bg-opacity-90 transition-all"
              >
                Contact Support
              </a>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-light-bg-tertiary py-8 border-t border-gray-200">
        <div className="container mx-auto px-4 text-center">
          <p className="text-light-text-tertiary">&copy; 2024 FilingReader. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage;