import React, { useState } from 'react';
import { Check, Sparkles } from 'lucide-react';

const PricingSection = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const PricingCard = ({ title, description, price, features, isPopular }) => (
    <div className={`
      relative bg-white rounded-xl
      ${isPopular ? 'border-2 border-secondary shadow-xl' : 'border border-gray-200'} 
      transition-all duration-300 hover:shadow-2xl hover:-translate-y-1
    `}>
      {isPopular && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2">
          <div className="bg-secondary text-white px-4 py-1 rounded-full text-sm font-medium flex items-center gap-1">
            <Sparkles className="w-4 h-4" />
            Most Popular
          </div>
        </div>
      )}
      
      <div className="p-8">
        <div className="mb-8">
          <h3 className="text-2xl font-bold mb-2 text-light-text tracking-tight">{title}</h3>
          <p className="text-light-text-tertiary">{description}</p>
        </div>

        <div className="mb-8">
          <div className="flex items-baseline gap-1">
            <span className="text-4xl font-bold text-light-text">
              ${isMonthly ? price : price * 10}
            </span>
            <span className="text-light-text-tertiary">
              /{isMonthly ? 'mo' : 'yr'}
            </span>
          </div>
          {isMonthly && price > 0 && (
            <p className="text-sm text-light-text-tertiary mt-1">
              Save ${price * 2} with annual billing
            </p>
          )}
        </div>

        <div className="space-y-4 mb-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="flex items-start gap-3 text-light-text-secondary"
            >
              <Check className="w-5 h-5 text-secondary flex-shrink-0 mt-0.5" />
              <span className="leading-tight">{feature}</span>
            </div>
          ))}
        </div>

        <a
          href="https://app.filingreader.com/?page=plan"
          target="_blank"
          rel="noopener noreferrer"
          className={`
            w-full py-3 px-4 rounded-lg transition-all duration-200
            inline-block text-center font-medium
            ${isPopular 
              ? 'bg-secondary text-white hover:bg-opacity-90 shadow-lg shadow-secondary/20 hover:shadow-secondary/30' 
              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }
          `}
        >
          Get started
        </a>
      </div>
    </div>
  );

  return (
    <section id="pricing" className="bg-gradient-to-b from-light-bg-secondary to-white py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-light-text tracking-tight">
            Simple, transparent pricing
          </h2>
          <p className="text-xl text-light-text-secondary max-w-2xl mx-auto">
            Choose the plan that's right for you
          </p>
        </div>

        <div className="flex justify-center mb-16">
          <div className="bg-white p-1 rounded-full border border-gray-200 shadow-sm">
            <button
              className={`
                px-6 py-2 rounded-full transition-all duration-200
                ${isMonthly 
                  ? 'bg-secondary text-white shadow-md' 
                  : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
                }
              `}
              onClick={() => setIsMonthly(true)}
            >
              Monthly
            </button>
            <button
              className={`
                px-6 py-2 rounded-full transition-all duration-200
                ${!isMonthly 
                  ? 'bg-secondary text-white shadow-md' 
                  : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
                }
              `}
              onClick={() => setIsMonthly(false)}
            >
              Annual
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          <PricingCard
            title="FREE"
            description="For individual research projects"
            price={0}
            features={[
              "Access 2 stock exchanges",
              "Track 3 companies",
              "Weekly e-mail notifications"
            ]}
          />
          <PricingCard
            title="PRO"
            description="For regular research and/or tracking multiple companies"
            price={16}
            features={[
              "Access a minimum of 5 exchanges",
              "Translate unlimited filings",
              "Track unlimited companies",
              "Create personalized feeds",
              "Advanced search",
              "Realtime, hourly, daily and weekly e-mail notifications",
              "Create RSS feeds"
            ]}
            isPopular={true}
          />
          <PricingCard
            title="TEAM"
            description="For organizations that want multiple user accounts"
            price={24}
            features={[
              "All the benefits of Pro plus:",
              "Multiple user accounts",
              "Create team feeds",
              "Share saved filings, translations and feeds within your team",
              "Lower cost per user"
            ]}
          />
        </div>
      </div>
    </section>
  );
};

export default PricingSection;