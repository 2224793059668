import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAnalytics from '../hooks/useAnalytics';
import MetaTags from '../components/utils/MetaTags';
import NewsHeader from '../components/news-wire/NewsHeader';
import NewsFilters from '../components/news-wire/NewsFilters';
import CategoryPills from '../components/news-wire/CategoryPills';
import NewsCard from '../components/news-wire/NewsCard';
import NewsSkeleton from '../components/news-wire/NewsSkeleton';
import { Button } from '../components/ui/button';

// Empty State Component
const EmptyState = () => (
  <div className="flex flex-col items-center justify-center py-12">
    <div className="bg-light-bg-secondary p-6 rounded-full mb-4">
      <AlertTriangle className="w-12 h-12 text-secondary" />
    </div>
    <h3 className="text-xl font-medium mb-2 text-light-text">No stories found</h3>
    <p className="text-light-text-secondary text-center max-w-md">
      Try adjusting your filters or search query to find more stories
    </p>
  </div>
);

const NewsWirePage = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // State management
  const [stories, setStories] = useState([]);
  const [categories, setCategories] = useState([
    { id: 'all', name: 'All Categories', count: 0 },
    { id: 'earnings', name: 'Earnings', count: 0 },
    { id: 'deals', name: 'Deals', count: 0 },
    { id: 'regulatory', name: 'Regulatory', count: 0 },
    { id: 'management', name: 'Management', count: 0 }
  ]);
  const [sectors, setSectors] = useState([{ id: 'all', name: 'All Sectors' }]);
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Get selected filters from URL params
  const selectedCategory = searchParams.get('category') || 'all';
  const selectedExchange = searchParams.get('exchange') || 'all';
  const selectedImportance = searchParams.get('importance') || 'all';
  const selectedSector = searchParams.get('sector') || 'all';

  // Data fetching
  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/news?` +
          `category=${selectedCategory}&` +
          `exchange=${selectedExchange}&` +
          `importance=${selectedImportance}&` +
          `sector=${selectedSector}&` +
          `page=${currentPage}&` +
          `limit=10` +
          (searchQuery ? `&company=${encodeURIComponent(searchQuery)}` : '')
        );

        if (!response.ok) throw new Error('Failed to fetch news');

        const data = await response.json();
        setStories(data.stories || []);
        if (data.categories) setCategories(data.categories);
        if (data.sectors) {
          setSectors([{ id: 'all', name: 'All Sectors' }, ...data.sectors]);
        }
        setTotalPages(data.pagination?.totalPages || 1);

        analytics.trackCustomEvent('News', 'View Filtered', {
          category: selectedCategory,
          exchange: selectedExchange,
          importance: selectedImportance,
          sector: selectedSector
        });
      } catch (err) {
        setError(err.message);
        analytics.trackError('News Fetch', err.message);
        setStories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [selectedCategory, selectedExchange, selectedImportance, selectedSector, currentPage, searchQuery, analytics]);

  const handleFilterChange = (type, value) => {
    setCurrentPage(1);
    const newParams = { ...Object.fromEntries(searchParams) };
    newParams[type] = value;
    setSearchParams(newParams);
    analytics.trackCustomEvent('News', `Change ${type}`, value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    analytics.trackCustomEvent('News', 'Search', e.target.value);
  };

  const handleNewsClick = (story) => {
    const date = new Date(story.story_date);
    const formattedDate = date.toISOString().split('T')[0];
    const url = `/news-wire/${story.exchange_code}/${formattedDate}/${story.slug}`;
    navigate(url); // This will use client-side routing
  };

  return (
    <div className="min-h-screen bg-light-bg">
      <MetaTags page="news-wire" />
      
      <NewsHeader 
        searchQuery={searchQuery}
        onSearchChange={handleSearchChange}
      />

      <NewsFilters
        isMobile={true}
        isExpanded={isFilterExpanded}
        selectedExchange={selectedExchange}
        selectedImportance={selectedImportance}
        selectedSector={selectedSector}
        onExchangeChange={(value) => handleFilterChange('exchange', value)}
        onImportanceChange={(value) => handleFilterChange('importance', value)}
        onSectorChange={(value) => handleFilterChange('sector', value)}
        onToggle={() => setIsFilterExpanded(!isFilterExpanded)}
        sectors={sectors}
      />

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar Filters */}
          <div className={`lg:w-64 flex-shrink-0 ${isFilterExpanded ? 'block' : 'hidden lg:block'}`}>
            <NewsFilters
              isMobile={false}
              selectedExchange={selectedExchange}
              selectedImportance={selectedImportance}
              selectedSector={selectedSector}
              onExchangeChange={(value) => handleFilterChange('exchange', value)}
              onImportanceChange={(value) => handleFilterChange('importance', value)}
              onSectorChange={(value) => handleFilterChange('sector', value)}
              sectors={sectors}
            />
          </div>

          {/* News Content */}
          <div className="flex-grow">
            <CategoryPills
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={(value) => handleFilterChange('category', value)}
            />

            {loading ? (
              <NewsSkeleton count={3} />
            ) : error ? (
              <div className="flex items-center justify-center py-12 text-red-600">
                <AlertTriangle className="w-6 h-6 mr-2" />
                <span>{error}</span>
              </div>
            ) : stories.length === 0 ? (
              <EmptyState />
            ) : (
              <div className="space-y-6">
                {stories.map((story) => (
                  <NewsCard
                    key={story.id}
                    story={story}
                    onClick={handleNewsClick}
                    onSectorClick={(sector) => handleFilterChange('sector', sector)}
                  />
                ))}

                {/* Pagination */}
                {totalPages > 1 && (
                  <div className="flex justify-center gap-2 mt-8">
                    <Button
                      variant="outline"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                      className="w-24"
                    >
                      Previous
                    </Button>
                    <div className="flex items-center gap-1">
                      {Array.from({ length: totalPages }, (_, i) => i + 1)
                        .filter(p => p === 1 || p === totalPages || Math.abs(p - currentPage) <= 1)
                        .map((page, i, arr) => (
                          <React.Fragment key={page}>
                            {i > 0 && arr[i - 1] !== page - 1 && (
                              <span className="px-2 text-gray-400">...</span>
                            )}
                            <Button
                              variant={currentPage === page ? "secondary" : "outline"}
                              onClick={() => setCurrentPage(page)}
                              className="w-10"
                            >
                              {page}
                            </Button>
                          </React.Fragment>
                        ))}
                    </div>
                    <Button
                      variant="outline"
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                      className="w-24"
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsWirePage;