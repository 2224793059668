import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';
import './LandingPage.css'; 
import useAnalytics from '../hooks/useAnalytics';
import { ReactComponent as Logo } from '../assets/filingreader-logo.svg';
import PricingSection from '../features/pricing/PricingSection';
import AIAnalysisSection from '../features/AIAnalysisSection';
import APISection from '../features/api/APISection';
import HeroSection from '../features/hero/HeroSection';
import FeaturesSection from '../features/FeaturesSection';
import Navbar from '../layouts/Navbar';
import Footer from '../components/Footer';
import MetaTags from '../components/utils/MetaTags';

const LandingPage = () => {
  const analytics = useAnalytics();
  const searchRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

  // Track user engagement time
  useEffect(() => {
    const interval = setInterval(() => {
      const engagementTime = Date.now() - lastInteractionTime;
      analytics.trackCustomEvent('Engagement', 'Time on Page', null, engagementTime / 1000);
    }, 30000); // Track every 30 seconds

    return () => clearInterval(interval);
  }, [lastInteractionTime, analytics]);

  // Handle scroll tracking
  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setScrollY(newScrollY);
      setLastInteractionTime(Date.now());

      // Track scroll depth
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = (newScrollY / height) * 100;
      
      if (scrollPercentage >= 25 && scrollPercentage < 50) {
        analytics.trackCustomEvent('Scroll', 'Reached 25%');
      } else if (scrollPercentage >= 50 && scrollPercentage < 75) {
        analytics.trackCustomEvent('Scroll', 'Reached 50%');
      } else if (scrollPercentage >= 75 && scrollPercentage < 90) {
        analytics.trackCustomEvent('Scroll', 'Reached 75%');
      } else if (scrollPercentage >= 90) {
        analytics.trackCustomEvent('Scroll', 'Reached 90%');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [analytics]);

  // Track search behavior
  useEffect(() => {
    if (searchQuery.length > 2) {
      setIsLoading(true);
      setIsDropdownOpen(true);
      
      const startTime = Date.now();
      analytics.trackUserAction('Search', 'Query Entered', searchQuery);
      
      fetch(`${process.env.REACT_APP_API_URL}/search?query=${encodeURIComponent(searchQuery)}`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data);
          setIsLoading(false);
          
          const duration = Date.now() - startTime;
          analytics.trackUserAction('Search', 'Results Received', searchQuery, {
            resultCount: data.length,
            duration: duration
          });
        })
        .catch(error => {
          setIsLoading(false);
          analytics.trackError('Search Error', error.message, {
            query: searchQuery,
            timestamp: new Date().toISOString()
          });
        });
    } else {
      setSearchResults([]);
      setIsDropdownOpen(false);
    }
  }, [searchQuery, analytics]);

  // Track section visibility
  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          analytics.trackUserAction('Section View', 'Section Visible', entry.target.id);
          observer.unobserve(entry.target); // Only track first view
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.2 // 20% visibility required
    });

    ['features', 'ai-analysis', 'api', 'pricing'].forEach(sectionId => {
      const element = document.getElementById(sectionId);
      if (element) observer.observe(element);
    });

    return () => observer.disconnect();
  }, [analytics]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setLastInteractionTime(Date.now());
  };

  const handleSearchFocus = () => {
    analytics.trackUserAction('Search', 'Focus');
    setLastInteractionTime(Date.now());
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
    }
  };

  const handleCompanyClick = (company) => {
    analytics.trackConversion('Company Selected', {
      company: company.longNameEn,
      stockCode: company.stockCodes,
      source: 'search'
    });

    const stockCode = company.stockCodes.split(',')[0].trim();
    window.open(
      `https://app.filingreader.com/?feed=${encodeURIComponent(stockCode)}&company=${encodeURIComponent(company.longNameEn)}`,
      '_blank'
    );
  };

  return (
    <div className="font-sans text-light-text min-h-screen bg-light-bg">
      <MetaTags page="home" />
      <Navbar scrollY={scrollY} />

      <main>
        <HeroSection 
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          handleSearchFocus={handleSearchFocus}
          isLoading={isLoading}
          isDropdownOpen={isDropdownOpen}
          searchResults={searchResults}
          handleCompanyClick={handleCompanyClick}
          searchRef={searchRef}
        />

        <FeaturesSection />
        <AIAnalysisSection />
        <APISection />
        <PricingSection />
      </main>

      <Footer analytics={analytics} />
    </div>
  );
};

export default LandingPage;