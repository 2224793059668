// src/watchlists.js

// Icons for each sector (using lucide-react icon names)
export const sectorIcons = {
  'Animal Health': 'Syringe',
  'Dairy': 'Milk',
  'Steel': 'Factory',
  'Solar': 'Sun',
  'Banks': 'Building2',
  'Shanghai Top-30': 'Trophy',
  'Hong Kong Top-20': 'Award',
  'Automotive': 'Car',
  'Airlines': 'Plane',
  'Real Estate': 'Home',
  'Copper': 'CircleDollarSign',
  'Agriculture': 'Wheat',
  'Coal': 'Pickaxe',
  'Shipping': 'Ship',
  'Mining': 'Shovel',
  'Forestry': 'Trees',
  'Wind Energy': 'Wind',
  'Pharmaceuticals': 'Pill',
  'Airports': 'PlaneLanding',
  'Seafood & Aquafeed': 'Fish',
  'Oil & Gas': 'Flame'
};

// Optional: Add sector descriptions
export const sectorDescriptions = {
  'Animal Health': 'Companies focused on veterinary products and animal healthcare',
  'Dairy': 'Major dairy producers and processors in China',
  'Steel': 'Leading steel manufacturers and processors',
  // ... etc
};