import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ChevronLeft, ChevronRight, Loader2, FileText, Calendar } from 'lucide-react';

const FilingsTable = forwardRef(({ watchlist }, ref) => {
  const [filings, setFilings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);

  useImperativeHandle(ref, () => ({
    resetPage: () => setCurrentPage(1)
  }));

  const fetchFilings = async (page, selectedWatchlist) => {
    if (!selectedWatchlist) {
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/filings/watchlist?` + 
        `watchlist=${encodeURIComponent(selectedWatchlist)}&page=${page}&limit=15`,
        {
          headers: {
            'Accept': 'application/json',
            'Accept-Language': navigator.language || 'en-US'
          },
          // This ensures the referer header is sent
          referrerPolicy: 'strict-origin-when-cross-origin'
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFilings(data.data);
      setTotalPages(Math.min(data.pagination.totalPages, 10));

    } catch (error) {
      console.error('Error fetching filings:', error);
      setError(error.message || 'Failed to load filings');
      setFilings([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (watchlist) {
      fetchFilings(currentPage, watchlist);
    }
  }, [currentPage, watchlist]);

  const formatDateTime = (timestamp) => {
    try {
      return new Date(timestamp).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (err) {
      console.error('Date formatting error:', err);
      return 'Invalid date';
    }
  };

  const formatStockCode = (stockCode) => {
    const [exchange, code] = stockCode.split(':');
    return code ? `${exchange}:${code}` : stockCode;
  };

  if (error) {
    return (
      <div className="bg-red-50 p-4 rounded-lg">
        <div className="flex flex-col items-center text-center">
          <p className="text-red-600 mb-2">Error loading filings: {error}</p>
          <button
            onClick={() => fetchFilings(currentPage, watchlist)}
            className="px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  const Pagination = () => (
    <div className="flex items-center justify-between px-4 py-2 bg-gray-50 border-t border-gray-200">
      <button
        onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
        disabled={currentPage === 1 || loading}
        className={`flex items-center space-x-1 px-3 py-1 rounded-lg transition-all duration-150 ${
          currentPage === 1 || loading
            ? 'text-gray-400 cursor-not-allowed'
            : 'text-gray-600 hover:bg-white hover:text-secondary'
        }`}
      >
        <ChevronLeft className="w-4 h-4" />
        <span>Previous</span>
      </button>
      
      <span className="px-3 py-1 rounded-lg bg-white text-gray-600 text-sm">
        Page {currentPage} of {totalPages}
      </span>
      
      <button
        onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
        disabled={currentPage === totalPages || loading}
        className={`flex items-center space-x-1 px-3 py-1 rounded-lg transition-all duration-150 ${
          currentPage === totalPages || loading
            ? 'text-gray-400 cursor-not-allowed'
            : 'text-gray-600 hover:bg-white hover:text-secondary'
        }`}
      >
        <span>Next</span>
        <ChevronRight className="w-4 h-4" />
      </button>
    </div>
  );

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      {/* Desktop View */}
      <div className="hidden md:block overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 border-b border-gray-200">
              <th className="w-32 px-4 py-2 text-left text-light-text-secondary font-medium">Code</th>
              <th className="w-64 px-4 py-2 text-left text-light-text-secondary font-medium">Company</th>
              <th className="px-4 py-2 text-left text-light-text-secondary font-medium">
                <div className="flex items-center">
                  <FileText className="w-4 h-4 mr-1.5" />
                  Filing
                </div>
              </th>
              <th className="w-24 px-4 py-2 text-center text-light-text-secondary font-medium">Pages</th>
              <th className="w-44 px-4 py-2 text-left text-light-text-secondary font-medium">Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">
                  <div className="px-4 py-8 text-center">
                    <div className="flex items-center justify-center">
                      <Loader2 className="w-6 h-6 animate-spin text-secondary" />
                      <span className="ml-2 text-light-text-secondary">Loading {watchlist} filings...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : filings.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <div className="px-4 py-8 text-center text-light-text-secondary">
                    No filings found for {watchlist}
                  </div>
                </td>
              </tr>
            ) : (
              filings.map((filing, index) => (
                <tr 
                  key={`${filing.stockCodes}-${filing.timeStamps}-${index}`}
                  className="group border-b border-gray-100 last:border-b-0 hover:bg-light-bg-secondary"
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="w-32 px-4 py-2">
                    <div className="font-mono text-light-text-secondary bg-light-bg-secondary px-2 py-0.5 rounded w-fit">
                      {formatStockCode(filing.stockCodes)}
                    </div>
                  </td>
                  <td className="w-64 px-4 py-2">
                    <a 
                      href={`https://app.filingreader.com/?feed=${encodeURIComponent(filing.stockCodes)}&company=${encodeURIComponent(filing.longNameEn)}`}
                      target="_blank"
                      rel="noopener noreferrer" 
                      className="text-light-text hover:text-secondary transition-colors font-medium block truncate"
                    >
                      {filing.longNameEn}
                    </a>
                  </td>
                  <td className="px-4 py-2 min-w-[300px]">
                    <div className="group/title relative">
                      <a 
                        href={`https://app.filingreader.com/filing?result=${filing.bulletinIds}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-light-text hover:text-secondary flex items-center relative pl-5"
                      >
                        <FileText className="w-4 h-4 flex-shrink-0 absolute left-0 opacity-0 group-hover/title:opacity-100 transition-opacity duration-200" />
                        <span className="font-medium hover:underline">
                          {filing.titlesTranslated || filing.titles || 'No title available'}
                        </span>
                      </a>
                      {filing.titlesTranslated && filing.titles && hoveredRow === index && (
                        <div className="absolute z-10 bg-gray-900 text-white p-2 rounded-lg text-sm mt-1 max-w-lg shadow-xl">
                          {filing.titles}
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="w-24 px-4 py-2 text-center">
                    <span className="inline-flex items-center justify-center min-w-[40px] px-2 py-1 bg-light-bg-secondary text-light-text-secondary rounded text-sm">
                      {filing.pageCount || '-'}
                    </span>
                  </td>
                  <td className="w-44 px-4 py-2">
                    <span className="text-light-text-secondary whitespace-nowrap">
                      {formatDateTime(filing.timeStamps)}
                    </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        {loading ? (
          <div className="px-4 py-8 text-center">
            <div className="flex items-center justify-center">
              <Loader2 className="w-6 h-6 animate-spin text-secondary" />
              <span className="ml-2 text-light-text-secondary">Loading {watchlist} filings...</span>
            </div>
          </div>
        ) : filings.length === 0 ? (
          <div className="px-4 py-8 text-center text-light-text-secondary">
            No filings found for {watchlist}
          </div>
        ) : (
          <div className="divide-y divide-gray-100">
            {filings.map((filing, index) => (
              <div key={`${filing.stockCodes}-${filing.timeStamps}-${index}`} className="p-4">
                <div className="flex items-start justify-between mb-2">
                  <div className="font-mono text-light-text-secondary bg-light-bg-secondary px-2 py-0.5 rounded">
                    {formatStockCode(filing.stockCodes)}
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="inline-flex items-center px-2 py-1 bg-light-bg-secondary text-light-text-secondary rounded text-sm">
                      {filing.pageCount || '-'} pages
                    </span>
                    <span className="text-light-text-secondary text-sm">
                      {formatDateTime(filing.timeStamps)}
                    </span>
                  </div>
                </div>
                <div className="mb-2">
                  <a 
                    href={`https://app.filingreader.com/?feed=${encodeURIComponent(filing.stockCodes)}&company=${encodeURIComponent(filing.longNameEn)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light-text hover:text-secondary transition-colors font-medium block"
                  >
                    {filing.longNameEn}
                  </a>
                </div>
                <a 
                  href={`https://app.filingreader.com/filing?result=${filing.bulletinIds}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-start text-light-text hover:text-secondary"
                >
                  <FileText className="w-4 h-4 mt-0.5 mr-1.5 flex-shrink-0" />
                  <span className="font-medium">
                    {filing.titlesTranslated || filing.titles || 'No title available'}
                  </span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>

      {!loading && filings.length > 0 && <Pagination />}
    </div>
  );
});

FilingsTable.displayName = 'FilingsTable';

export default FilingsTable;