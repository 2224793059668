import React from 'react';
import { Helmet } from 'react-helmet';

const defaultMeta = {
  siteName: 'FilingReader',
  title: 'FilingReader | Global Stock Exchange Filings in One Place',
  description: 'Access over 10 million stock exchange filings from Asia to Europe. Real-time updates, AI-powered analysis, and multi-language support for global investors and researchers.',
  type: 'website',
  url: 'https://filingreader.com',
  image: '/og-image.jpg',
  twitterHandle: '@filingreader',
  keywords: [
    'stock exchange filings',
    'financial documents',
    'global markets',
    'AI analysis',
    'regulatory filings',
    'investment research',
    'market intelligence',
    'financial data',
    'global stock exchanges',
    'company filings'
  ]
};

const pageMeta = {
  home: {
    title: 'FilingReader | Global Stock Exchange Filings in One Place',
    description: 'Access over 10 million stock exchange filings worldwide. Real-time updates, AI-powered analysis, and multi-language support for making informed investment decisions.',
  },
  about: {
    title: 'About FilingReader | Our Mission and Story',
    description: 'Learn how FilingReader is revolutionizing access to global financial information with AI-powered analysis and real-time updates from major stock exchanges worldwide.',
  },
  'chinese-exchanges': {
    title: 'Chinese Stock Exchange Filings | SSE, SZSE & HKEX | FilingReader',
    description: 'Access real-time filings from Shanghai (SSE), Shenzhen (SZSE), and Hong Kong (HKEX) stock exchanges. Get comprehensive coverage of Chinese market regulatory filings and financial reports.',
    keywords: [
      'Chinese stock exchanges',
      'SSE filings',
      'SZSE filings',
      'HKEX filings',
      'Chinese market data',
      'Shanghai Stock Exchange',
      'Shenzhen Stock Exchange',
      'Hong Kong Exchange',
      'Chinese corporate filings',
      'China market research'
    ]
  },
  // In MetaTags.js, add to pageMeta:
  'news-wire': {
    title: 'News Wire | AI-Generated Coverage of Asian Markets | FilingReader',
    description: 'Get real-time, AI-powered news coverage of important company announcements and filings from Asian markets. Stay informed with our comprehensive news wire service.',
    keywords: [
      'financial news',
      'AI news generation',
      'company announcements',
      'Asian markets news',
      'stock market news',
      'corporate filings',
      'business news',
      'real-time updates',
      'market intelligence',
      'financial reporting'
    ]
  },
  api: {
    title: 'FilingReader API | Integrate Global Filing Data',
    description: 'Power your applications with FilingReader\'s comprehensive API. Access millions of filings from global stock exchanges with our easy-to-use RESTful API.',
  },
  pricing: {
    title: 'FilingReader Pricing | Plans for Every Need',
    description: 'Choose the perfect plan for your filing access needs. From individual research to enterprise solutions, we offer flexible pricing with powerful features.',
  }
};

const MetaTags = ({ page = 'home', customMeta = {} }) => {
  const meta = {
    ...defaultMeta,
    ...pageMeta[page],
    ...customMeta
  };

  const formatTitle = (title) => {
    return title.length <= 60 ? title : title.substring(0, 57) + '...';
  };

  const formatDescription = (desc) => {
    return desc.length <= 160 ? desc : desc.substring(0, 157) + '...';
  };

  // Add canonical URL construction
  const getCanonicalUrl = (page) => {
    // Don't modify app subdomain URLs
    if (window.location.hostname === 'app.filingreader.com') {
      return window.location.href;
    }
    // For main site, always use non-www version
    const baseUrl = 'https://filingreader.com';

    // If we're on www, redirect to non-www
    if (window.location.hostname === 'www.filingreader.com') {
      window.location.href = window.location.href.replace('www.', '');
    }
    switch (page) {
      case 'home':
        return baseUrl; 
      case 'news-wire':
        return `${baseUrl}/news-wire`;
      case 'about':
        return `${baseUrl}/about`;
      case 'chinese-exchanges':
        return `${baseUrl}/chinese-exchanges`;
      case 'api-docs':
        return `${baseUrl}/api-docs`;
      default:
        return baseUrl;
    }
  };

  return (
    <Helmet>
      {/* Add canonical URL */}
      <link rel="canonical" href={getCanonicalUrl(page)} />
  
      {/* Primary Meta Tags */}
      <title>{formatTitle(meta.title)}</title>
      <meta name="title" content={formatTitle(meta.title)} />
      <meta name="description" content={formatDescription(meta.description)} />
      <meta name="keywords" content={meta.keywords.join(', ')} />
      <meta name="preferred-domain" content="filingreader.com" />
  
      {/* Favicon configuration */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#302d4c" />
      <meta name="msapplication-TileColor" content="#302d4c" />
      <meta name="theme-color" content="#302d4c" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:title" content={formatTitle(meta.title)} />
      <meta property="og:description" content={formatDescription(meta.description)} />
      <meta property="og:image" content={`${meta.url}/og-image.png`} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content={meta.siteName} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={meta.twitterHandle} />
      <meta name="twitter:title" content={formatTitle(meta.title)} />
      <meta name="twitter:description" content={formatDescription(meta.description)} />
      <meta name="twitter:image" content={`${meta.url}/og-image.png`} />

      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content={meta.siteName} />
      
      {/* Structured Data for Google */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": meta.siteName,
          "description": meta.description,
          "applicationCategory": "FinanceTool",
          "operatingSystem": "Web",
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "1250"
          }
        })}
      </script>
    </Helmet>
  );
};

export default MetaTags;