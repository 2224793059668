import React, { useState } from 'react';
import { 
  Globe, 
  Bell, 
  BarChart2, 
  Code2, 
  Languages, 
  Rss,
  ChevronRight,
  Building2,
  PlaneTakeoff,
  Car,
  Pill,
  Server,
  Pickaxe,
  Check,
  Leaf,
  PawPrint,
  CircleDollarSign,
  Milk,
  Trees,
  Flame,
  Fish,
  Ship,
  Sun,
  Factory,
  Wind,
  ArrowRight
} from 'lucide-react';

const FeaturesSection = () => {
  const [activeFeature, setActiveFeature] = useState('global-coverage');
  
  const features = [
    {
      id: 'global-coverage',
      icon: <Globe className="w-6 h-6" />,
      title: 'Global Coverage',
      description: 'Access filings from stock exchanges worldwide',
      details: {
        title: 'Comprehensive Global Coverage',
        description: 'Access regulatory filings, annual reports, and financial statements from major exchanges and emerging markets worldwide.',
        benefits: [
          'Real-time access to major exchanges like NYSE, LSE, and SSE',
          'Historical filing archives dating back 10+ years',
          'Coverage of emerging markets in Asia, Africa, and South America',
          'Filing type filters and importance levels'
        ]
      }
    },
    {
      id: 'real-time-alerts',
      icon: <Bell className="w-6 h-6" />,
      title: 'Real-time Alerts',
      description: 'Never miss important filings',
      details: {
        title: 'Customizable Alert System',
        description: 'Stay informed with instant notifications about new filings and custom keyword alerts.',
        benefits: [
          'Email notifications',
          'Keyword and phrase-based alerts',
          'RSS feed generation for any key word or company',
          'Customizable notification frequency',
          'Smart Scan alerts for AI summaries of important filings', 
        ]
      }
    },
    {
      id: 'ai-analytics',
      icon: <BarChart2 className="w-6 h-6" />,
      title: 'AI Analytics',
      description: 'Extract insights automatically',
      details: {
        title: 'AI-Powered Analytics',
        description: 'Leverage cutting-edge AI to analyze filings and extract key insights automatically.',
        benefits: [
          'Automated filing summaries and key points extraction',
          'Data extraction and visualization from filings',
          'Sentiment analysis',
          'Historical trend analysis',
          'Automated reports across exchanges and companies',
        ]
      }
    },
    {
      id: 'widgets',
      icon: <Code2 className="w-6 h-6" />,
      title: 'Embeddable Widgets and API',
      description: 'Integrate filing data anywhere',
      details: {
        title: 'Customizable Widgets',
        description: 'Seamlessly integrate FilingReader data into your website or internal tools.',
        benefits: [
          'Ready-to-use widget templates',
          'Customizable appearance',
          'Real-time data updates',
          'Comprehensive API'
        ]
      }
    },
    {
      id: 'translation',
      icon: <Languages className="w-6 h-6" />,
      title: 'Translation',
      description: 'Break language barriers',
      details: {
        title: 'Advanced Translation Features',
        description: 'Read filings in your preferred language with our advanced translation capabilities.',
        benefits: [
          'Support for 30+ languages',
          'Context-aware financial terminology translation',
          'Original and translated view side-by-side',
          'Bulk translation of multiple documents',
          'Download translations in various formats'
        ]
      }
    },
    {
      id: 'industry-feeds',
      icon: <Rss className="w-6 h-6" />,
      title: 'Industry Feeds',
      description: 'Curated sector updates',
      details: {
        title: 'Specialized Industry Coverage',
        description: 'Stay informed with curated industry-specific feeds and sector analysis.',
        benefits: [
          'Specialized feeds for major sectors',
          'Real-time industry trend tracking',
          'Cross-sector analysis',
          'Regulatory update monitoring'
        ]
      }
    }
  ];

  const industries = [
    { icon: <Leaf />, name: 'Agriculture' },
    { icon: <PlaneTakeoff />, name: 'Aviation' },
    { icon: <Building2 />, name: 'Airports' },
    { icon: <PawPrint />, name: 'Animal Health' },
    { icon: <Car />, name: 'Automotive' },
    { icon: <Building2 />, name: 'Banks' },
    { icon: <Pickaxe />, name: 'Coal' },
    { icon: <CircleDollarSign />, name: 'Copper' },
    { icon: <Milk />, name: 'Dairy' },
    { icon: <Trees />, name: 'Forestry' },
    { icon: <Pickaxe />, name: 'Mining' },
    { icon: <Flame />, name: 'Oil & Gas' },
    { icon: <Pill />, name: 'Pharmaceuticals' },
    { icon: <Building2 />, name: 'Real Estate' },
    { icon: <Fish />, name: 'Seafood & Aquafeed' },
    { icon: <Ship />, name: 'Shipping' },
    { icon: <Sun />, name: 'Solar' },
    { icon: <Factory />, name: 'Steel' },
    { icon: <Server />, name: 'Technology' },
    { icon: <Wind />, name: 'Wind Energy' }
  ].map(industry => ({
    ...industry,
    icon: React.cloneElement(industry.icon, { className: 'w-4 h-4' })
  }));

  const activeFeatureData = features.find(f => f.id === activeFeature);

  const FeatureButton = ({ feature, isActive, onClick }) => (
    <button
      onClick={onClick}
      className={`
        w-full text-left p-4 rounded-xl transition-all duration-200
        flex items-center justify-between group relative
        hover:bg-light-bg-secondary
        ${isActive ? 'bg-secondary bg-opacity-10 shadow-sm' : ''}
      `}
    >
      <div className="flex items-center gap-4">
        <div className={`
          p-2 rounded-lg transition-colors duration-200
          ${isActive ? 'bg-secondary text-white' : 'bg-light-bg-secondary text-secondary group-hover:bg-secondary/10'}
        `}>
          {feature.icon}
        </div>
        <div>
          <h3 className={`
            font-medium transition-colors duration-200
            ${isActive ? 'text-secondary' : 'text-light-text group-hover:text-secondary'}
          `}>
            {feature.title}
          </h3>
          <p className="text-sm text-light-text-secondary">
            {feature.description}
          </p>
        </div>
      </div>
      <ChevronRight className={`
        w-5 h-5 transition-all duration-200
        ${isActive ? 'text-secondary rotate-90' : 'text-light-text-secondary group-hover:translate-x-1'}
      `} />
    </button>
  );

  const BenefitItem = ({ benefit }) => (
    <div className="flex items-start gap-3 p-3 rounded-lg hover:bg-light-bg-secondary transition-colors duration-200">
      <div className="p-1 bg-secondary/10 rounded-md mt-0.5">
        <Check className="w-4 h-4 text-secondary" />
      </div>
      <span className="text-light-text-secondary leading-relaxed">{benefit}</span>
    </div>
  );

  const IndustryTag = ({ industry }) => (
    <div className="group relative">
      <div className="
        flex items-center gap-2 p-3 rounded-lg text-light-text-secondary
        hover:text-secondary transition-all duration-200 cursor-pointer
        hover:bg-secondary/5 border border-transparent hover:border-secondary/10
      ">
        <div className="transition-transform duration-200 group-hover:scale-110">
          {industry.icon}
        </div>
        <span className="text-sm font-medium">{industry.name}</span>
      </div>
    </div>
  );

  return (
    <section className="bg-gradient-to-b from-light-bg to-white py-24">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 text-light-text tracking-tight">
            Comprehensive Platform for Investors and Analysts
          </h2>
          <p className="text-xl text-light-text-secondary max-w-2xl mx-auto">
            Everything you need to analyze global financial filings
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {/* Feature Navigation */}
          <div className="space-y-2">
            {features.map((feature) => (
              <FeatureButton
                key={feature.id}
                feature={feature}
                isActive={activeFeature === feature.id}
                onClick={() => setActiveFeature(feature.id)}
              />
            ))}
          </div>

          {/* Feature Details */}
          <div className="lg:col-span-2 bg-white p-8 rounded-xl border border-gray-200 shadow-sm">
            <div className="space-y-8">
              <div>
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-secondary/10 rounded-xl">
                    {activeFeatureData.icon}
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-light-text tracking-tight">
                      {activeFeatureData.details.title}
                    </h3>
                    <p className="text-light-text-secondary mt-1">
                      {activeFeatureData.details.description}
                    </p>
                  </div>
                </div>
                
                <div className="space-y-2">
                  {activeFeatureData.details.benefits.map((benefit, index) => (
                    <BenefitItem key={index} benefit={benefit} />
                  ))}
                </div>
              </div>

              {activeFeature === 'industry-feeds' && (
                <div className="pt-8 border-t border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <h4 className="text-xl font-semibold text-light-text">
                      Featured Industry Coverage
                    </h4>
                    <button className="text-secondary hover:text-secondary/80 transition-colors flex items-center gap-2 text-sm font-medium">
                      View all industries
                      <ArrowRight className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
                    {industries.map((industry, index) => (
                      <IndustryTag key={index} industry={industry} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;