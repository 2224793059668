import React from 'react';
import { Link } from 'react-router-dom';
import { Bell } from 'lucide-react';
import { ReactComponent as Logo } from '../assets/filingreader-logo.svg';

const Footer = ({ analytics }) => {
  const handleNewsletterSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    
    analytics.trackConversion('Newsletter Signup', {
      location: 'footer',
      email: email
    });

    // Add newsletter signup logic here
  };

  const socialLinks = [
    { name: 'Twitter', href: 'https://twitter.com/filingreader' },
    { name: 'LinkedIn', href: 'https://linkedin.com/company/filingreader' }
  ];

  const quickLinks = [
    { name: 'Home', to: '/' },
    { name: 'About', to: '/about' },
    { name: 'API', to: '/api-docs' },
    { name: 'Pricing', to: '#pricing' }
  ];

  const legalLinks = [
    { name: 'Terms', to: '/terms' },
    { name: 'Privacy', to: '/privacy' },
    { name: 'Contact', href: 'mailto:support@filingreader.com' }
  ];

  const bottomLinks = [
    { name: 'Terms of Service', to: '/terms' },
    { name: 'Privacy Policy', to: '/privacy' },
    { name: 'Security', to: '/security' }
  ];

  return (
    <footer className="bg-gradient-to-b from-hero to-hero-darker py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <Link to="/" className="inline-block group">
              <Logo className="h-10 w-auto transform transition-transform duration-200 group-hover:scale-105" />
            </Link>
            <p className="text-white/80 text-lg leading-relaxed">
              Empowering financial decisions with comprehensive global filing access.
            </p>
            <div className="flex space-x-6">
              {socialLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-secondary transition-all duration-200 hover:translate-y-0.5"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-6">
            <h4 className="text-white font-semibold text-xl">Quick Links</h4>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <ul className="space-y-4">
                  {quickLinks.map((link) => (
                    <li key={link.name}>
                      <Link
                        to={link.to}
                        className="text-white/80 hover:text-secondary transition-all duration-200 hover:translate-x-0.5 inline-block"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <ul className="space-y-4">
                  {legalLinks.map((link) => (
                    <li key={link.name}>
                      {link.to ? (
                        <Link
                          to={link.to}
                          className="text-white/80 hover:text-secondary transition-all duration-200 hover:translate-x-0.5 inline-block"
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <a
                          href={link.href}
                          className="text-white/80 hover:text-secondary transition-all duration-200 hover:translate-x-0.5 inline-block"
                        >
                          {link.name}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Newsletter */}
          <div className="space-y-6">
            <h4 className="text-white font-semibold text-xl">Stay Updated</h4>
            <p className="text-white/80 text-lg leading-relaxed">
              Get the latest updates on new features and supported exchanges.
            </p>
            <form onSubmit={handleNewsletterSubmit} className="space-y-3">
              <input 
                type="email" 
                name="email"
                placeholder="Enter your email" 
                className="w-full bg-hero-darker text-white px-4 py-3 rounded-lg 
                         focus:outline-none border border-white/10 
                         focus:border-secondary focus:ring-1 focus:ring-secondary
                         placeholder-white/50 transition-all duration-200" 
                onFocus={() => analytics.trackUserAction('Newsletter', 'Form Focus')}
              />
              <button 
                type="submit" 
                className="w-full bg-secondary text-white px-4 py-3 rounded-lg 
                         hover:bg-opacity-90 transition-all duration-200 
                         transform hover:translate-y-0.5
                         flex items-center justify-center space-x-3"
              >
                <Bell className="w-5 h-5" />
                <span className="font-medium">Subscribe to Updates</span>
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-white/10">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-white/60">
              &copy; {new Date().getFullYear()} FilingReader. All rights reserved.
            </p>
            <div className="flex space-x-8">
              {bottomLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.to}
                  className="text-white/60 hover:text-secondary transition-all duration-200"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;