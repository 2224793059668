import React from 'react';
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";

const CategoryPills = ({ categories, selectedCategory, onCategoryChange }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {categories.map((category) => (
      <Button
        key={category.id}
        variant={selectedCategory === category.id ? "secondary" : "ghost"}
        size="sm"
        className={`
          rounded-full transition-all duration-200 
          ${selectedCategory === category.id 
            ? 'shadow-md shadow-secondary/20' 
            : 'hover:shadow-sm'
          }
        `}
        onClick={() => onCategoryChange(category.id)}
      >
        {category.name}
        {category.count > 0 && (
          <Badge 
            variant="secondary" 
            className={`
              ml-2 transition-colors duration-200
              ${selectedCategory === category.id 
                ? 'bg-white text-secondary' 
                : 'bg-secondary/10'
              }
            `}
          >
            {category.count}
          </Badge>
        )}
      </Button>
    ))}
  </div>
);

export default CategoryPills;