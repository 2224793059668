import React from 'react';
import { Card, CardContent } from "../ui/card";

const NewsCardSkeleton = () => (
  <Card className="animate-pulse">
    <CardContent className="p-6">
      {/* Header Section */}
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="h-6 w-20 bg-gray-200 rounded-full"></div>
          <div className="h-6 w-28 bg-gray-200 rounded-full"></div>
        </div>
        <div className="h-5 w-24 bg-gray-200 rounded-full"></div>
      </div>
      
      {/* Title and Summary */}
      <div className="space-y-3 mb-6">
        <div className="h-7 w-4/5 bg-gray-200 rounded-lg"></div>
        <div className="space-y-2">
          <div className="h-4 w-full bg-gray-200 rounded"></div>
          <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
          <div className="h-4 w-4/6 bg-gray-200 rounded"></div>
        </div>
      </div>
      
      {/* Footer Section */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="h-5 w-5 bg-gray-200 rounded"></div>
          <div className="h-5 w-32 bg-gray-200 rounded"></div>
          <div className="h-5 w-20 bg-gray-200 rounded"></div>
        </div>
        <div className="flex space-x-2">
          <div className="h-6 w-20 bg-gray-200 rounded-full"></div>
          <div className="h-6 w-20 bg-gray-200 rounded-full"></div>
        </div>
      </div>
    </CardContent>
  </Card>
);

const NewsSkeleton = ({ count = 3 }) => (
  <div className="space-y-6">
    {Array.from({ length: count }).map((_, i) => (
      <NewsCardSkeleton key={i} />
    ))}
  </div>
);

export default NewsSkeleton;