import React from 'react';
import { Clock } from 'lucide-react';

const ExchangeInfoSection = () => {
  return (
    <section className="bg-white py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center mb-16">Exchange Information</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Shanghai Stock Exchange */}
          <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h3 className="text-xl font-semibold mb-1">Shanghai Stock Exchange</h3>
                <p className="text-sm text-gray-600">Founded in 1990, the Shanghai Stock Exchange (SSE) is China's largest stock exchange by market capitalization.</p>
              </div>
              <span className="text-2xl font-bold">CN</span>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <div className="w-5 h-5 mr-2">🏢</div>
                  Listed Companies: 2,000+
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Clock className="w-5 h-5 mr-2" />
                  Trading Hours: 9:30 - 15:00 CST
                </div>
              </div>

              <div>
                <p className="text-sm font-medium mb-2">Filing Types:</p>
                <div className="flex flex-wrap gap-2">
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Annual Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Semi-Annual Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Quarterly Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Corporate Announcements</span>
                </div>
              </div>
            </div>
          </div>

          {/* Shenzhen Stock Exchange */}
          <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h3 className="text-xl font-semibold mb-1">Shenzhen Stock Exchange</h3>
                <p className="text-sm text-gray-600">Established in 1990, the Shenzhen Stock Exchange (SZE) focuses on innovative and high-tech companies.</p>
              </div>
              <span className="text-2xl font-bold">CN</span>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <div className="w-5 h-5 mr-2">🏢</div>
                  Listed Companies: 2,600+
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Clock className="w-5 h-5 mr-2" />
                  Trading Hours: 9:30 - 15:00 CST
                </div>
              </div>

              <div>
                <p className="text-sm font-medium mb-2">Filing Types:</p>
                <div className="flex flex-wrap gap-2">
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Annual Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Interim Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Quarterly Statements</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">ESG Reports</span>
                </div>
              </div>
            </div>
          </div>

          {/* Hong Kong Stock Exchange */}
          <div className="bg-white p-6 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-start justify-between mb-4">
              <div>
                <h3 className="text-xl font-semibold mb-1">Hong Kong Stock Exchange</h3>
                <p className="text-sm text-gray-600">Asia's third-largest stock exchange, HKEX serves as a crucial gateway between mainland China and international markets.</p>
              </div>
              <span className="text-2xl font-bold">HK</span>
            </div>

            <div className="space-y-4">
              <div>
                <div className="flex items-center text-sm text-gray-600 mb-2">
                  <div className="w-5 h-5 mr-2">🏢</div>
                  Listed Companies: 2,500+
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Clock className="w-5 h-5 mr-2" />
                  Trading Hours: 9:30 - 16:00 HKT
                </div>
              </div>

              <div>
                <p className="text-sm font-medium mb-2">Filing Types:</p>
                <div className="flex flex-wrap gap-2">
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Annual Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Interim Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">ESG Reports</span>
                  <span className="px-2 py-1 bg-gray-100 rounded-md text-xs">Inside Information</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExchangeInfoSection;