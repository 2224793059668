import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const ChineseSpinningGlobe = () => {
  const chartRef = useRef(null);
  const globeRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Define colors at component level
  const activeColor = "#64E9B1";   // Soft teal-green
  const inactiveColor = "#FF9776"; // Coral orange

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const getTradingHours = (exchange) => {
    const tradingHours = {
      "SSE": { open: 9.5, close: 15, timezone: "Asia/Shanghai" },
      "SZSE": { open: 9.5, close: 15, timezone: "Asia/Shanghai" },
      "HKEX": { open: 9.5, close: 16, timezone: "Asia/Hong_Kong" },
      "DEFAULT": { open: 9, close: 17, timezone: "UTC" }
    };

    const exchangeHours = tradingHours[exchange.id] || tradingHours.DEFAULT;
  
    try {
      // Get the current date in the exchange's timezone
      const dateFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: exchangeHours.timezone,
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
  
      const parts = dateFormatter.formatToParts(currentTime);
      const dateParts = {};
      parts.forEach(part => {
        if (part.type !== 'literal') {
          dateParts[part.type] = part.value;
        }
      });
  
      // Check for weekend
      const isWeekend = dateParts.weekday === 'Sunday' || dateParts.weekday === 'Saturday';
      
      if (isWeekend) {
        return {
          isOpen: false,
          statusColor: inactiveColor,
          status: "Closed (Weekend)"
        };
      }
  
      // Convert hour to 24-hour format
      let hourDecimal;
      if (dateParts.dayPeriod === 'PM' && dateParts.hour !== '12') {
        hourDecimal = parseInt(dateParts.hour) + 12;
      } else if (dateParts.dayPeriod === 'AM' && dateParts.hour === '12') {
        hourDecimal = 0;
      } else {
        hourDecimal = parseInt(dateParts.hour);
      }
      
      // Add minutes as decimal
      hourDecimal += parseInt(dateParts.minute) / 60;
  
      // Check if within trading hours
      const isOpen = hourDecimal >= exchangeHours.open && hourDecimal < exchangeHours.close;
      const timeStr = `${dateParts.hour}:${dateParts.minute} ${dateParts.dayPeriod}`;
      
      return {
        isOpen,
        statusColor: isOpen ? activeColor : inactiveColor,
        status: isOpen ? `Open (${timeStr})` : `Closed (${timeStr})`
      };
    } catch (error) {
      console.error(`Error getting trading hours for ${exchange.id}:`, error);
      return {
        isOpen: false,
        statusColor: inactiveColor,
        status: "Status unavailable"
      };
    }
  };

  const exchanges = [
    { id: "SSE", title: "Shanghai Stock Exchange", latitude: 31.2304, longitude: 121.4737, value: 6200, region: "China" },
    { id: "SZSE", title: "Shenzhen Stock Exchange", latitude: 22.5431, longitude: 114.0579, value: 4100, region: "China" },
    { id: "HKEX", title: "Hong Kong Exchanges", latitude: 22.3193, longitude: 114.1694, value: 4500, region: "China" }
  ].map(exchange => ({
    ...exchange,
    ...getTradingHours(exchange)
  }));

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("globeDiv", am4maps.MapChart);
    chartRef.current = chart;

    try {
      // Basic chart setup
      chart.geodata = am4geodata_worldLow;
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = "rotateLongLat";
      chart.deltaLatitude = -20;
      chart.padding(20,20,20,20);

      chart.adapter.add("deltaLatitude", function(delatLatitude) {
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });

      // Configure map appearance
      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#353348");
      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;

      // Configure map colors and styling
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      polygonSeries.exclude = ["AQ"]; // Exclude Antarctica
      
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "";
      polygonTemplate.fill = am4core.color("#1A182C");
      polygonTemplate.stroke = am4core.color("#E45435");
      polygonTemplate.strokeWidth = 0.5;
      polygonTemplate.fillOpacity = 0.8;

      // Create series for China and Hong Kong (highlighted)
      let chinaSeries = chart.series.push(new am4maps.MapPolygonSeries());
      chinaSeries.useGeodata = true;
      chinaSeries.include = ["CN", "HK"];
      let chinaTemplate = chinaSeries.mapPolygons.template;
      chinaTemplate.fill = am4core.color("#1A182C");
      chinaTemplate.stroke = am4core.color("#E45435");
      chinaTemplate.strokeWidth = 0.5;
      chinaTemplate.fillOpacity = 1;
      
      let hs = chinaTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#F9D493");

      let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.line.stroke = am4core.color("#E45435");
      graticuleSeries.mapLines.template.line.strokeOpacity = 0.1;
      graticuleSeries.fitExtent = false;

      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
      let imageTemplate = imageSeries.mapImages.template;
      
      // Create container for marker and glow effects
      let container = imageTemplate.createChild(am4core.Container);
      container.nonScaling = true;

      // Create multiple glow layers with different sizes and opacities
      const createGlowLayer = (size, opacity) => {
        let diamond = container.createChild(am4core.Triangle);
        diamond.width = size;
        diamond.height = size;
        diamond.rotation = 45; // Rotate to create diamond shape
        diamond.propertyFields.fill = "statusColor";
        diamond.fillOpacity = opacity;
        diamond.horizontalCenter = "middle";
        diamond.verticalCenter = "middle";
        return diamond;
      };

      // Create glow layers
      createGlowLayer(32, 0.08); // Outer glow
      createGlowLayer(24, 0.12);
      createGlowLayer(16, 0.16);
      
      // Create main marker
      let marker = container.createChild(am4core.Triangle);
      marker.width = 8;
      marker.height = 8;
      marker.rotation = 45; // Diamond shape
      marker.propertyFields.fill = "statusColor";
      marker.horizontalCenter = "middle";
      marker.verticalCenter = "middle";
      marker.strokeOpacity = 0; // Remove stroke

      // Modify pulse animation for open exchanges
      const pulseAnimation = (diamond) => {
        diamond.animate([{
          property: "scale",
          from: 1,
          to: 1.2
        }, {
          property: "opacity",
          from: 1,
          to: 0.7
        }], 2000, am4core.ease.sinInOut).loop = true;
      };

      imageSeries.events.on("datavalidated", function() {
        imageSeries.mapImages.each((mapImage) => {
          if (mapImage.dataItem.dataContext.isOpen) {
            let circles = mapImage.children.values.filter(child => 
              child instanceof am4core.Container
            );
            circles.forEach(container => {
              container.children.values.forEach(circle => {
                pulseAnimation(circle);
              });
            });
          }
        });
      });

      // Enhanced hover state
      let containerHover = container.states.create("hover");
      containerHover.properties.scale = 1.3;
      
      // Set property fields
      imageTemplate.propertyFields.latitude = "latitude";
      imageTemplate.propertyFields.longitude = "longitude";

      // Configure tooltip
      imageTemplate.tooltipHTML = `
        <div style="background-color: #1A182C; color: #ffffff; padding: 12px; border-radius: 6px; border: 1px solid #E45435; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
          <div style="color: #F9D493; font-weight: bold; font-size: 1.1em; margin-bottom: 8px;">{title}</div>
          <div style="margin-bottom: 4px;">Market Cap: ${'{value}'}B</div>
          <div style="margin-bottom: 4px;">Status: {status}</div>
          <div>Region: {region}</div>
        </div>
      `;

      // Set the data for the markers
      imageSeries.data = exchanges;

      // Create rotation animation
      globeRef.current = setInterval(() => {
        if (chart && !chart.isDisposed()) {
          chart.deltaLongitude += 0.2;
        }
      }, 50);

      // Disable zoom
      chart.mouseWheelBehavior = "none";
      
    } catch (error) {
      console.error("Error creating chart:", error);
    }

    return () => {
      if (globeRef.current) {
        clearInterval(globeRef.current);
      }
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [currentTime]);

  return (
    <div 
      id="globeDiv" 
      className="w-full h-96 md:h-[500px] rounded-lg"
      style={{ minHeight: "400px" }}
    />
  );
};

export default ChineseSpinningGlobe;