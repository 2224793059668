import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const SpinningGlobe = () => {
  const chartRef = useRef(null);
  const globeRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Define colors at component level
  const activeColor = "#64E9B1";  // Soft teal-green
  const inactiveColor = "#FF9776"; // Coral orange

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const getTradingHours = (exchange) => {
    // Trading hours configuration with timezone names
    const tradingHours = {
      // North America
      "NYSE": { open: 9.5, close: 16, timezone: "America/New_York" },
      "NASDAQ": { open: 9.5, close: 16, timezone: "America/New_York" },
      "TMX": { open: 9.5, close: 16, timezone: "America/Toronto" },
      "NEO": { open: 9.5, close: 16, timezone: "America/Toronto" },
      "BMV": { open: 8.5, close: 15, timezone: "America/Mexico_City" },
      "BSX": { open: 9.5, close: 16, timezone: "Atlantic/Bermuda" },

      // Asia Pacific
      "SSE": { open: 9.5, close: 15, timezone: "Asia/Shanghai" },
      "SZSE": { open: 9.5, close: 15, timezone: "Asia/Shanghai" },
      "JPX": { open: 9, close: 15.5, timezone: "Asia/Tokyo" },
      "HKEX": { open: 9.5, close: 16, timezone: "Asia/Hong_Kong" },
      "KRX": { open: 9, close: 15.5, timezone: "Asia/Seoul" },
      "BSE": { open: 9.25, close: 15.5, timezone: "Asia/Kolkata" },
      "NSE": { open: 9.25, close: 15.5, timezone: "Asia/Kolkata" },
      "SGX": { open: 9, close: 17, timezone: "Asia/Singapore" },
      "TWSE": { open: 9, close: 13.5, timezone: "Asia/Taipei" },
      "IDX": { open: 9, close: 15.5, timezone: "Asia/Jakarta" },
      "BURSA": { open: 9, close: 17, timezone: "Asia/Kuala_Lumpur" },
      "SET": { open: 10, close: 16.5, timezone: "Asia/Bangkok" },
      "PSE": { open: 9.5, close: 15.5, timezone: "Asia/Manila" },
      "HNX": { open: 9, close: 15, timezone: "Asia/Ho_Chi_Minh" },
      "HOSE": { open: 9, close: 15, timezone: "Asia/Ho_Chi_Minh" },
      "CSE": { open: 9.5, close: 14.5, timezone: "Asia/Colombo" },
      "DSE": { open: 10, close: 14.5, timezone: "Asia/Dhaka" },
      "PSX": { open: 9.5, close: 15.5, timezone: "Asia/Karachi" },
      "KASE": { open: 11.5, close: 17, timezone: "Asia/Almaty" },
      "MSE": { open: 10, close: 13, timezone: "Asia/Ulaanbaatar" },

      // Europe
      "LSE": { open: 8, close: 16.5, timezone: "Europe/London" },
      "EURONEXT": { open: 9, close: 17.5, timezone: "Europe/Paris" },
      "DB": { open: 9, close: 17.5, timezone: "Europe/Berlin" },
      "SIX": { open: 9, close: 17.5, timezone: "Europe/Zurich" },
      "MOEX": { open: 9.5, close: 19, timezone: "Europe/Moscow" },
      "NASDAQ_NORDIC": { open: 9, close: 17.5, timezone: "Europe/Stockholm" },
      "BME": { open: 9, close: 17.5, timezone: "Europe/Madrid" },
      "BORSA_ITALIANA": { open: 9, close: 17.5, timezone: "Europe/Rome" },
      "WIENER_BORSE": { open: 9, close: 17.5, timezone: "Europe/Vienna" },
      "BSE_HUNGARY": { open: 9, close: 17, timezone: "Europe/Budapest" },
      "LUXSE": { open: 9, close: 17.5, timezone: "Europe/Luxembourg" },
      "ATHEX": { open: 10, close: 17.2, timezone: "Europe/Athens" },
      "GPW": { open: 9, close: 17, timezone: "Europe/Warsaw" },
      "PSE_PRAGUE": { open: 9, close: 16.2, timezone: "Europe/Prague" },
      "BVB": { open: 10, close: 18, timezone: "Europe/Bucharest" },
      "BSE_SOFIA": { open: 10, close: 16.5, timezone: "Europe/Sofia" },
      "ZSE": { open: 9, close: 16, timezone: "Europe/Zagreb" },

      // Middle East
      "TADAWUL": { open: 10, close: 15, timezone: "Asia/Riyadh" },
      "ADX": { open: 10, close: 14, timezone: "Asia/Dubai" },
      "DFM": { open: 10, close: 14, timezone: "Asia/Dubai" },
      "QSE": { open: 9.5, close: 13.5, timezone: "Asia/Qatar" },
      "TASE": { open: 10, close: 17.5, timezone: "Asia/Jerusalem" },
      "MSM": { open: 10, close: 14, timezone: "Asia/Muscat" },
      "BHB": { open: 9.5, close: 13, timezone: "Asia/Bahrain" },
      "ISX": { open: 10, close: 13, timezone: "Asia/Baghdad" },
      "TSE_IRAN": { open: 9, close: 12.5, timezone: "Asia/Tehran" },

      // Australia/NZ/Pacific
      "ASX": { open: 10, close: 16, timezone: "Australia/Sydney" },
      "NZX": { open: 10, close: 16.45, timezone: "Pacific/Auckland" },
      "PNGX": { open: 10, close: 16, timezone: "Pacific/Port_Moresby" },
      "SPSE": { open: 10, close: 15, timezone: "Pacific/Fiji" },

      // Africa
      "JSE": { open: 9, close: 17, timezone: "Africa/Johannesburg" },
      "NSE_NIGERIA": { open: 10, close: 14.5, timezone: "Africa/Lagos" },
      "CASA": { open: 9.5, close: 15.5, timezone: "Africa/Casablanca" },
      "EGX": { open: 10.5, close: 14.5, timezone: "Africa/Cairo" },
      "NSE_KENYA": { open: 9.5, close: 15, timezone: "Africa/Nairobi" },
      "SEM": { open: 9.5, close: 13, timezone: "Indian/Mauritius" },
      "BSE_BOTSWANA": { open: 10, close: 13, timezone: "Africa/Gaborone" },
      "GSE": { open: 10, close: 15, timezone: "Africa/Accra" },
      "NSX": { open: 9, close: 17, timezone: "Africa/Windhoek" },
      "RSE": { open: 9, close: 14, timezone: "Africa/Kigali" },
      "USE": { open: 10, close: 15, timezone: "Africa/Kampala" },
      "ZSE_ZIM": { open: 10, close: 16, timezone: "Africa/Harare" },

      // South America
      "B3": { open: 10, close: 17, timezone: "America/Sao_Paulo" },
      "SANTIAGO": { open: 9.5, close: 16, timezone: "America/Santiago" },
      "BVC": { open: 9, close: 16, timezone: "America/Bogota" },
      "BVL": { open: 9.5, close: 16, timezone: "America/Lima" },
      "BCBA": { open: 11, close: 17, timezone: "America/Argentina/Buenos_Aires" },
      "BVM": { open: 10, close: 17, timezone: "America/Montevideo" },
      "BBV": { open: 8.5, close: 16, timezone: "America/La_Paz" },
      "BVC_VENEZUELA": { open: 9.5, close: 13, timezone: "America/Caracas" },
      // [Trading hours configuration to be added]
      "DEFAULT": { open: 9, close: 17, timezone: "UTC" }
    };

    const exchangeHours = tradingHours[exchange.id] || tradingHours.DEFAULT;
  
    try {
      // Get the current date in the exchange's timezone
      const dateFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: exchangeHours.timezone,
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
  
      const parts = dateFormatter.formatToParts(currentTime);
      const dateParts = {};
      parts.forEach(part => {
        if (part.type !== 'literal') {
          dateParts[part.type] = part.value;
        }
      });
  
      // Check for weekend
      const isWeekend = dateParts.weekday === 'Sunday' || dateParts.weekday === 'Saturday';
      
      if (isWeekend) {
        return {
          isOpen: false,
          statusColor: inactiveColor,
          status: "Closed (Weekend)"
        };
      }
  
      // Convert hour to 24-hour format
      let hourDecimal;
      if (dateParts.dayPeriod === 'PM' && dateParts.hour !== '12') {
        hourDecimal = parseInt(dateParts.hour) + 12;
      } else if (dateParts.dayPeriod === 'AM' && dateParts.hour === '12') {
        hourDecimal = 0;
      } else {
        hourDecimal = parseInt(dateParts.hour);
      }
      
      // Add minutes as decimal
      hourDecimal += parseInt(dateParts.minute) / 60;
  
      // Check if within trading hours
      const isOpen = hourDecimal >= exchangeHours.open && hourDecimal < exchangeHours.close;
      const timeStr = `${dateParts.hour}:${dateParts.minute} ${dateParts.dayPeriod}`;
      
      return {
        isOpen,
        statusColor: isOpen ? activeColor : inactiveColor,
        status: isOpen ? `Open (${timeStr})` : `Closed (${timeStr})`
      };
    } catch (error) {
      console.error(`Error getting trading hours for ${exchange.id}:`, error);
      return {
        isOpen: false,
        statusColor: inactiveColor,
        status: "Status unavailable"
      };
    }
  };

  const exchanges = [
    // North America
    { id: "NYSE", title: "New York Stock Exchange", latitude: 40.7128, longitude: -74.0060, value: 25800, region: "North America" },
    { id: "NASDAQ", title: "NASDAQ", latitude: 40.7128, longitude: -74.0060, value: 21300, region: "North America" },
    { id: "TMX", title: "TMX Group (Canada)", latitude: 43.6532, longitude: -79.3832, value: 2900, region: "North America" },
    { id: "BMV", title: "Bolsa Mexicana de Valores", latitude: 19.4326, longitude: -99.1332, value: 450, region: "North America" },
    { id: "BSX", title: "Bermuda Stock Exchange", latitude: 32.2949, longitude: -64.7859, value: 290, region: "North America" },
    { id: "NEO", title: "NEO Exchange (Canada)", latitude: 43.6532, longitude: -79.3832, value: 100, region: "North America" },
    
    // Asia
    { id: "SSE", title: "Shanghai Stock Exchange", latitude: 31.2304, longitude: 121.4737, value: 6200, region: "Asia" },
    { id: "JPX", title: "Japan Exchange Group", latitude: 35.6762, longitude: 139.6503, value: 5800, region: "Asia" },
    { id: "HKEX", title: "Hong Kong Exchanges", latitude: 22.3193, longitude: 114.1694, value: 4500, region: "Asia" },
    { id: "SZSE", title: "Shenzhen Stock Exchange", latitude: 22.5431, longitude: 114.0579, value: 4100, region: "Asia" },
    { id: "NSE", title: "National Stock Exchange of India", latitude: 19.0176, longitude: 72.8561, value: 3900, region: "Asia" },
    { id: "BSE", title: "BSE India Limited", latitude: 18.9387, longitude: 72.8353, value: 3800, region: "Asia" },
    { id: "KRX", title: "Korea Exchange", latitude: 37.5665, longitude: 126.9780, value: 1800, region: "Asia" },
    { id: "TWSE", title: "Taiwan Stock Exchange", latitude: 25.0330, longitude: 121.5654, value: 1700, region: "Asia" },
    { id: "SGX", title: "Singapore Exchange", latitude: 1.3521, longitude: 103.8198, value: 650, region: "Asia" },
    { id: "SET", title: "Stock Exchange of Thailand", latitude: 13.7563, longitude: 100.5018, value: 550, region: "Asia" },
    { id: "IDX", title: "Indonesia Stock Exchange", latitude: -6.1751, longitude: 106.8650, value: 580, region: "Asia" },
    { id: "BURSA", title: "Bursa Malaysia", latitude: 3.1502, longitude: 101.7077, value: 400, region: "Asia" },
    { id: "PSE", title: "Philippine Stock Exchange", latitude: 14.5995, longitude: 120.9842, value: 270, region: "Asia" },
    { id: "KASE", title: "Kazakhstan Stock Exchange", latitude: 43.2220, longitude: 76.8512, value: 50, region: "Asia" },
    { id: "HNX", title: "Hanoi Stock Exchange", latitude: 21.0285, longitude: 105.8542, value: 120, region: "Asia" },
    { id: "HOSE", title: "Ho Chi Minh Stock Exchange", latitude: 10.7756, longitude: 106.7019, value: 190, region: "Asia" },
    { id: "CSE", title: "Colombo Stock Exchange", latitude: 6.9271, longitude: 79.8612, value: 12, region: "Asia" },
    { id: "MSE", title: "Mongolian Stock Exchange", latitude: 47.9184, longitude: 106.9177, value: 2, region: "Asia" },
    { id: "DSE", title: "Dhaka Stock Exchange", latitude: 23.8103, longitude: 90.4125, value: 46, region: "Asia" },
    { id: "PSX", title: "Pakistan Stock Exchange", latitude: 24.8607, longitude: 67.0011, value: 42, region: "Asia" },
    
    // Europe
    { id: "EURONEXT", title: "Euronext", latitude: 48.8566, longitude: 2.3522, value: 6800, region: "Europe" },
    { id: "LSE", title: "London Stock Exchange Group", latitude: 51.5074, longitude: -0.1278, value: 3900, region: "Europe" },
    { id: "DB", title: "Deutsche Boerse", latitude: 50.1109, longitude: 8.6821, value: 2400, region: "Europe" },
    { id: "SIX", title: "SIX Swiss Exchange", latitude: 47.3769, longitude: 8.5417, value: 2100, region: "Europe" },
    { id: "NASDAQ_NORDIC", title: "NASDAQ Nordic & Baltic", latitude: 59.3293, longitude: 18.0686, value: 1900, region: "Europe" },
    { id: "BME", title: "BME Spanish Exchanges", latitude: 40.4168, longitude: -3.7038, value: 870, region: "Europe" },
    { id: "BORSA_ITALIANA", title: "Borsa Italia", latitude: 45.4642, longitude: 9.1900, value: 780, region: "Europe" },
    { id: "MOEX", title: "Moscow Exchange", latitude: 55.7558, longitude: 37.6173, value: 447, region: "Europe" },
    { id: "WIENER_BORSE", title: "Vienna Stock Exchange", latitude: 48.2082, longitude: 16.3738, value: 140, region: "Europe" },
    { id: "BSE_HUNGARY", title: "Budapest Stock Exchange", latitude: 47.4979, longitude: 19.0402, value: 35, region: "Europe" },
    { id: "LUXSE", title: "Luxembourg Stock Exchange", latitude: 49.6116, longitude: 6.1319, value: 55, region: "Europe" },
    { id: "ATHEX", title: "Athens Stock Exchange", latitude: 37.9838, longitude: 23.7275, value: 65, region: "Europe" },
    { id: "GPW", title: "Warsaw Stock Exchange", latitude: 52.2297, longitude: 21.0122, value: 150, region: "Europe" },
    { id: "PSE_PRAGUE", title: "Prague Stock Exchange", latitude: 50.0755, longitude: 14.4378, value: 50, region: "Europe" },
    { id: "BVB", title: "Bucharest Stock Exchange", latitude: 44.4268, longitude: 26.1025, value: 30, region: "Europe" },
    { id: "BSE_SOFIA", title: "Bulgarian Stock Exchange", latitude: 42.6977, longitude: 23.3219, value: 5, region: "Europe" },
    { id: "ZSE", title: "Zagreb Stock Exchange", latitude: 45.8150, longitude: 15.9819, value: 23, region: "Europe" },
    
    // Middle East
    { id: "TADAWUL", title: "Saudi Stock Exchange", latitude: 24.7136, longitude: 46.6753, value: 2600, region: "Middle East" },
    { id: "ADX", title: "Abu Dhabi Securities Exchange", latitude: 24.4539, longitude: 54.3773, value: 750, region: "Middle East" },
    { id: "QSE", title: "Qatar Stock Exchange", latitude: 25.2867, longitude: 51.5333, value: 160, region: "Middle East" },
    { id: "DFM", title: "Dubai Financial Market", latitude: 25.2048, longitude: 55.2708, value: 155, region: "Middle East" },
    { id: "TASE", title: "Tel Aviv Stock Exchange", latitude: 32.0853, longitude: 34.7818, value: 235, region: "Middle East" },
    { id: "MSM", title: "Muscat Securities Market", latitude: 23.5880, longitude: 58.3829, value: 55, region: "Middle East" },
    { id: "BHB", title: "Bahrain Bourse", latitude: 26.2285, longitude: 50.5860, value: 28, region: "Middle East" },
    { id: "ISX", title: "Iraq Stock Exchange", latitude: 33.3152, longitude: 44.3661, value: 10, region: "Middle East" },
    { id: "TSE_IRAN", title: "Tehran Stock Exchange", latitude: 35.6892, longitude: 51.3890, value: 140, region: "Middle East" },
    
    // Australia/NZ/Pacific
    { id: "ASX", title: "Australian Securities Exchange", latitude: -33.8688, longitude: 151.2093, value: 1600, region: "Australia/NZ" },
    { id: "NZX", title: "NZX Limited", latitude: -41.2865, longitude: 174.7762, value: 130, region: "Australia/NZ" },
    { id: "PNGX", title: "Port Moresby Stock Exchange", latitude: -9.4438, longitude: 147.1803, value: 6, region: "Australia/NZ" },
    { id: "SPSE", title: "South Pacific Stock Exchange", latitude: -18.1416, longitude: 178.4419, value: 1, region: "Australia/NZ" },
    
    // Africa
    { id: "JSE", title: "Johannesburg Stock Exchange", latitude: -26.2041, longitude: 28.0473, value: 1100, region: "Africa" },
    { id: "NSE_NIGERIA", title: "Nigerian Stock Exchange", latitude: 6.4550, longitude: 3.3841, value: 60, region: "Africa" },
    { id: "CASA", title: "Casablanca Stock Exchange", latitude: 33.5731, longitude: -7.5898, value: 65, region: "Africa" },
    { id: "EGX", title: "Egyptian Exchange", latitude: 30.0444, longitude: 31.2357, value: 70, region: "Africa" },
    { id: "NSE_KENYA", title: "Nairobi Securities Exchange", latitude: -1.2921, longitude: 36.8219, value: 21, region: "Africa" },
    { id: "SEM", title: "Stock Exchange of Mauritius", latitude: -20.1609, longitude: 57.5012, value: 8, region: "Africa" },
    { id: "BSE_BOTSWANA", title: "Botswana Stock Exchange", latitude: -24.6282, longitude: 25.9231, value: 4, region: "Africa" },
    { id: "GSE", title: "Ghana Stock Exchange", latitude: 5.5560, longitude: -0.1969, value: 12, region: "Africa" },
    { id: "NSX", title: "Namibian Stock Exchange", latitude: -22.5609, longitude: 17.0658, value: 2.5, region: "Africa" },
    { id: "RSE", title: "Rwanda Stock Exchange", latitude: -1.9441, longitude: 30.0619, value: 3.5, region: "Africa" },
    { id: "USE", title: "Uganda Securities Exchange", latitude: 0.3136, longitude: 32.5811, value: 6, region: "Africa" },
    { id: "ZSE_ZIM", title: "Zimbabwe Stock Exchange", latitude: -17.8292, longitude: 31.0522, value: 7, region: "Africa" },
    
    // South America
    { id: "B3", title: "B3 (Brazil)", latitude: -23.5505, longitude: -46.6333, value: 780, region: "South America" },
    { id: "SANTIAGO", title: "Santiago Stock Exchange", latitude: -33.4489, longitude: -70.6693, value: 190, region: "South America" },
    { id: "BVC", title: "Colombian Securities Exchange", latitude: 4.7110, longitude: -74.0721, value: 85, region: "South America" },
    { id: "BVL", title: "Lima Stock Exchange", latitude: -12.0464, longitude: -77.0428, value: 70, region: "South America" },
    { id: "BCBA", title: "Buenos Aires Stock Exchange", latitude: -34.6037, longitude: -58.3816, value: 45, region: "South America" },
    { id: "BVM", title: "Montevideo Stock Exchange", latitude: -34.9011, longitude: -56.1645, value: 1, region: "South America" },
    { id: "BBV", title: "Bolivian Stock Exchange", latitude: -16.4897, longitude: -68.1193, value: 12, region: "South America" },
    { id: "BVC_VENEZUELA", title: "Caracas Stock Exchange", latitude: 10.5067, longitude: -66.9500, value: 2, region: "South America" }
  ].map(exchange => ({
    ...exchange,
    ...getTradingHours(exchange)
  }));

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("globeDiv", am4maps.MapChart);
    chartRef.current = chart;

    try {
      // Basic chart setup
      chart.geodata = am4geodata_worldLow;
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = "rotateLongLat";
      chart.deltaLatitude = -20;
      chart.padding(20,20,20,20);

      chart.adapter.add("deltaLatitude", function(delatLatitude) {
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });

      // Configure map appearance
      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#353348");
      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;

      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "";
      polygonTemplate.fill = am4core.color("#1A182C");
      polygonTemplate.stroke = am4core.color("#E45435");
      polygonTemplate.strokeWidth = 0.5;
      polygonTemplate.fillOpacity = 0.8;

      let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.line.stroke = am4core.color("#E45435");
      graticuleSeries.mapLines.template.line.strokeOpacity = 0.1;
      graticuleSeries.fitExtent = false;

      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = am4core.color("#F9D493");

      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
      let imageTemplate = imageSeries.mapImages.template;
      
      // Create container for marker and glow effects
      let container = imageTemplate.createChild(am4core.Container);
      container.nonScaling = true;

      // Create multiple glow layers with different sizes and opacities
      const createGlowLayer = (size, opacity) => {
        let diamond = container.createChild(am4core.Triangle);
        diamond.width = size;
        diamond.height = size;
        diamond.rotation = 45; // Rotate to create diamond shape
        diamond.propertyFields.fill = "statusColor";
        diamond.fillOpacity = opacity;
        diamond.horizontalCenter = "middle";
        diamond.verticalCenter = "middle";
        return diamond;
      };

      // Create glow layers
      createGlowLayer(32, 0.08); // Outer glow
      createGlowLayer(24, 0.12);
      createGlowLayer(16, 0.16);
      
      // Create main marker
      let marker = container.createChild(am4core.Triangle);
      marker.width = 8;
      marker.height = 8;
      marker.rotation = 45; // Diamond shape
      marker.propertyFields.fill = "statusColor";
      marker.horizontalCenter = "middle";
      marker.verticalCenter = "middle";
      marker.strokeOpacity = 0; // Remove stroke

      // Modify pulse animation for open exchanges
      const pulseAnimation = (diamond) => {
        diamond.animate([{
          property: "scale",
          from: 1,
          to: 1.2
        }, {
          property: "opacity",
          from: 1,
          to: 0.7
        }], 2000, am4core.ease.sinInOut).loop = true;
      };

      imageSeries.events.on("datavalidated", function() {
        imageSeries.mapImages.each((mapImage) => {
          if (mapImage.dataItem.dataContext.isOpen) {
            let circles = mapImage.children.values.filter(child => 
              child instanceof am4core.Container
            );
            circles.forEach(container => {
              container.children.values.forEach(circle => {
                pulseAnimation(circle);
              });
            });
          }
        });
      });

      // Enhanced hover state
      let containerHover = container.states.create("hover");
      containerHover.properties.scale = 1.3;
      
      // Set property fields
      imageTemplate.propertyFields.latitude = "latitude";
      imageTemplate.propertyFields.longitude = "longitude";

      // Configure tooltip
      imageTemplate.tooltipHTML = `
        <div style="background-color: #1A182C; color: #ffffff; padding: 12px; border-radius: 6px; border: 1px solid #E45435; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
          <div style="color: #F9D493; font-weight: bold; font-size: 1.1em; margin-bottom: 8px;">{title}</div>
          <div style="margin-bottom: 4px;">Market Cap: ${'{value}'}B</div>
          <div style="margin-bottom: 4px;">Status: {status}</div>
          <div>Region: {region}</div>
        </div>
      `;

      // Set the data for the markers
      imageSeries.data = exchanges;

      // Create rotation animation
      globeRef.current = setInterval(() => {
        if (chart && !chart.isDisposed()) {
          chart.deltaLongitude += 0.2;
        }
      }, 50);

      // Disable zoom
      chart.mouseWheelBehavior = "none";
      
    } catch (error) {
      console.error("Error creating chart:", error);
    }

    return () => {
      if (globeRef.current) {
        clearInterval(globeRef.current);
      }
      if (chartRef.current) {
        chartRef.current.dispose();
      }
    };
  }, [currentTime]);

  return (
    <div 
      id="globeDiv" 
      className="w-full h-96 md:h-[500px] rounded-lg"
      style={{ minHeight: "400px" }}
    />
  );
};

export default SpinningGlobe;