import React from 'react';
import { Filter, ChevronDown, ChevronRight } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

// Mobile Filters Component
const MobileFilters = ({ selectedExchange, onExchangeChange, isExpanded, onToggle }) => (
  <div className="lg:hidden sticky top-0 bg-white z-10 p-4 border-b shadow-sm">
    <div className="flex items-center space-x-2">
      <Select
        value={selectedExchange}
        onValueChange={onExchangeChange}
      >
        <SelectTrigger className="w-32">
          <SelectValue placeholder="Exchange" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">All</SelectItem>
          <SelectItem value="SSE">SSE</SelectItem>
          <SelectItem value="SZSE">SZSE</SelectItem>
          <SelectItem value="HKEX">HKEX</SelectItem>
        </SelectContent>
      </Select>
      <Button
        variant="outline"
        onClick={onToggle}
        className="flex items-center gap-2"
      >
        <Filter className="w-4 h-4" />
        Filters
        {isExpanded ? (
          <ChevronDown className="w-4 h-4" />
        ) : (
          <ChevronRight className="w-4 h-4" />
        )}
      </Button>
    </div>
  </div>
);

// Sidebar Filters Component
const SidebarFilters = ({ 
  selectedExchange,
  selectedImportance,
  selectedSector,
  onExchangeChange,
  onImportanceChange,
  onSectorChange,
  sectors
}) => (
  <div className="space-y-6">
    {/* Exchange Filter */}
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-sm font-medium">Exchange</CardTitle>
      </CardHeader>
      <CardContent>
        <Select value={selectedExchange} onValueChange={onExchangeChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select Exchange" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Exchanges</SelectItem>
            <SelectItem value="SSE">Shanghai (SSE)</SelectItem>
            <SelectItem value="SZSE">Shenzhen (SZSE)</SelectItem>
            <SelectItem value="HKEX">Hong Kong (HKEX)</SelectItem>
          </SelectContent>
        </Select>
      </CardContent>
    </Card>

    {/* Importance Filter */}
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-sm font-medium">Importance</CardTitle>
      </CardHeader>
      <CardContent>
        <Select value={selectedImportance} onValueChange={onImportanceChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select Importance" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Levels</SelectItem>
            <SelectItem value="high">High</SelectItem>
            <SelectItem value="medium">Medium</SelectItem>
            <SelectItem value="low">Low</SelectItem>
          </SelectContent>
        </Select>
      </CardContent>
    </Card>

    {/* Sector Filter */}
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-sm font-medium">Sector</CardTitle>
      </CardHeader>
      <CardContent>
        <Select value={selectedSector} onValueChange={onSectorChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select Sector" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Sectors</SelectItem>
            {sectors.map((sector) => (
              <SelectItem key={sector.id} value={sector.id}>
                {sector.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </CardContent>
    </Card>
  </div>
);

const NewsFilters = ({
  isMobile,
  isExpanded,
  selectedExchange,
  selectedImportance,
  selectedSector,
  onExchangeChange,
  onImportanceChange,
  onSectorChange,
  onToggle,
  sectors
}) => {
  if (isMobile) {
    return (
      <MobileFilters
        selectedExchange={selectedExchange}
        onExchangeChange={onExchangeChange}
        isExpanded={isExpanded}
        onToggle={onToggle}
      />
    );
  }

  return (
    <SidebarFilters
      selectedExchange={selectedExchange}
      selectedImportance={selectedImportance}
      selectedSector={selectedSector}
      onExchangeChange={onExchangeChange}
      onImportanceChange={onImportanceChange}
      onSectorChange={onSectorChange}
      sectors={sectors}
    />
  );
};

export default NewsFilters;