import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft, Clock, Building2, FileText, Share2, Newspaper } from 'lucide-react';
import useAnalytics from '../../hooks/useAnalytics';

const NewsStory = () => {
  const { exchange, date, slug } = useParams();
  const analytics = useAnalytics();
  const [story, setStory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStory = async () => {
      try {
        // Update the API endpoint to use all parameters
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/news/${exchange}/${date}/${slug}`
        );
        if (!response.ok) throw new Error('Story not found');
        
        const data = await response.json();
        setStory(data);
        
        // Track page view
        analytics.trackCustomEvent('News', 'View Story', data.title);
      } catch (err) {
        setError(err.message);
        analytics.trackError('News Story View', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStory();
  }, [exchange, date, slug, analytics]);

  // Add scroll depth tracking
  useEffect(() => {
    if (!story) return;

    let lastTrackedPercentage = 0;
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      
      // Calculate scroll percentage
      const scrollPercentage = Math.round((scrollTop / (documentHeight - windowHeight)) * 100);
      
      // Track at 25%, 50%, 75%, and 100%
      const milestones = [25, 50, 75, 100];
      const currentMilestone = milestones.find(
        milestone => scrollPercentage >= milestone && lastTrackedPercentage < milestone
      );

      if (currentMilestone) {
        lastTrackedPercentage = currentMilestone;
        analytics.trackArticleScroll(story.id, currentMilestone);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [story, analytics]);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: story.title,
        text: story.summary,
        url: window.location.href
      });
    } else {
      navigator.clipboard.writeText(window.location.href);
      // Show toast notification here
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-light-bg py-20">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-32 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-light-bg py-20">
        <div className="container mx-auto px-4">
          <div className="bg-red-50 p-6 rounded-lg text-center">
            <p className="text-red-600 mb-4">{error}</p>
            <Link
              to="/news-wire"
              className="inline-flex items-center text-red-600 hover:text-red-700"
            >
              <ChevronLeft className="w-4 h-4 mr-1" />
              Back to News Wire
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (!story) return null;

  return (
    <div className="min-h-screen bg-light-bg py-20">
      <div className="container mx-auto px-4">
        {/* Back button */}
        <Link
          to="/news-wire"
          className="inline-flex items-center text-light-text-secondary hover:text-secondary mb-8"
        >
          <ChevronLeft className="w-4 h-4 mr-1" />
          Back to News Wire
        </Link>

        <div className="max-w-3xl mx-auto">
          {/* Header */}
          <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center text-light-text-secondary text-sm">
                <Clock className="w-4 h-4 mr-1" />
                <span>{new Date(story.timestamp).toLocaleString()}</span>
                <span className="mx-2">·</span>
                <span>{story.read_time} read</span>
              </div>
              <button
                onClick={handleShare}
                className="p-2 text-light-text-secondary hover:text-secondary rounded-lg hover:bg-light-bg-secondary transition-colors"
              >
                <Share2 className="w-4 h-4" />
              </button>
            </div>

            <h1 className="text-3xl font-bold text-light-text mb-4">
              {story.title}
            </h1>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Building2 className="w-4 h-4 mr-1 text-secondary" />
                <span className="font-medium text-light-text">
                  {story.company}
                </span>
                <span className="mx-2 text-light-text-tertiary">·</span>
                <span className="font-mono text-light-text-secondary">
                  {story.ticker}
                </span>
              </div>
              <div className="flex items-center text-light-text-secondary text-sm">
                <Newspaper className="w-4 h-4 mr-1" />
                <span>{story.category}</span>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
            <div className="prose max-w-none">
              <p className="text-light-text-secondary text-lg leading-relaxed">
                {story.summary}
              </p>
            </div>
          </div>

          {/* Source Documents */}
          {story.source_docs?.length > 0 && (
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h2 className="text-lg font-semibold text-light-text mb-4 flex items-center">
                <FileText className="w-5 h-5 mr-2 text-secondary" />
                Source Documents
              </h2>
              <div className="space-y-2">
                {story.source_docs.map((doc, index) => (
                  <a
                    key={index}
                    href={`https://app.filingreader.com/filing?id=${story.filing_ids[index]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p-3 rounded-lg hover:bg-light-bg-secondary transition-colors"
                  >
                    <FileText className="w-4 h-4 mr-2 text-light-text-secondary" />
                    <span className="text-light-text-secondary hover:text-secondary">
                      {doc}
                    </span>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsStory;