// services/analytics.js
import ReactGA from 'react-ga4';

class AnalyticsService {
  constructor() {
    this.initialized = false;
  }

  init() {
    if (!this.initialized) {
      ReactGA.initialize('G-YE6CH2MG43');
      this.initialized = true;
    }
  }

  // Page Views
  trackPageView(path) {
    ReactGA.send({ 
      hitType: "pageview", 
      page: path 
    });
  }

  // User Actions
  trackUserAction(category, action, label = null, value = null) {
    ReactGA.event({
      category,
      action,
      ...(label && { label }),
      ...(value && { value })
    });
  }

  // Search Events
  trackSearch(query, resultsCount) {
    this.trackUserAction('Search', 'Performed Search', query);
    this.trackUserAction('Search', 'Results Count', null, resultsCount);
  }

  // API Usage Events
  trackAPIUsage(endpoint, status, responseTime) {
    this.trackUserAction('API', 'API Call', endpoint, {
      status,
      responseTime
    });
  }

  // Feature Usage
  trackFeatureUsage(feature, action) {
    this.trackUserAction('Feature', action, feature);
  }

  // Error Events
  trackError(errorType, errorMessage, errorDetails = null) {
    this.trackUserAction('Error', errorType, errorMessage, {
      details: errorDetails
    });
  }

  // User Authentication Events
  trackAuthentication(action, method) {
    this.trackUserAction('Authentication', action, method);
  }

  // File Events
  trackFileAction(action, fileType, fileSize) {
    this.trackUserAction('Files', action, fileType, fileSize);
  }

  // Navigation Events
  trackNavigation(from, to) {
    this.trackUserAction('Navigation', 'Page Change', `${from} -> ${to}`);
  }

  // Form Events
  trackFormInteraction(formName, action, details = null) {
    this.trackUserAction('Form', action, formName, details);
  }

  // Performance Metrics
  trackPerformance(metric, value) {
    this.trackUserAction('Performance', metric, null, value);
  }

  // User Preferences
  trackPreferenceChange(preference, newValue) {
    this.trackUserAction('Preferences', 'Change', preference, {
      value: newValue
    });
  }

  // Session Events
  trackSessionStart() {
    this.trackUserAction('Session', 'Start', new Date().toISOString());
  }

  trackSessionEnd(duration) {
    this.trackUserAction('Session', 'End', null, duration);
  }

  // Conversion Events
  trackConversion(type, value = null) {
    ReactGA.event({
      category: 'Conversion',
      action: type,
      ...(value && { value })
    });
  }

  // Custom Events
  trackCustomEvent(category, action, label = null, value = null) {
    this.trackUserAction(category, action, label, value);
  }

  // News Specific Events
  trackNewsView(category, source) {
    this.trackUserAction('News', 'View Category', category, {
      source: source
    });
  }

  trackStoryRead(storyId, title, category, readTime) {
    this.trackUserAction('News', 'Read Story', title, {
      storyId,
      category,
      readTime
    });
  }

  trackNewsSearch(query, resultCount) {
    this.trackUserAction('News', 'Search', query, {
      resultCount,
      timestamp: new Date().toISOString()
    });
  }

  trackNewsFilter(filterType, value) {
    this.trackUserAction('News', 'Apply Filter', filterType, {
      value,
      timestamp: new Date().toISOString()
    });
  }

  trackNewsShare(storyId, platform) {
    this.trackUserAction('News', 'Share', platform, {
      storyId,
      timestamp: new Date().toISOString()
    });
  }

  // Track RSS subscription
  trackRSSSubscribe(category) {
    this.trackConversion('RSS Subscription', {
      category,
      timestamp: new Date().toISOString()
    });
  }

  // Track email digest subscription
  trackDigestSubscribe(exchange, frequency) {
    this.trackConversion('Digest Subscription', {
      exchange,
      frequency,
      timestamp: new Date().toISOString()
    });
  }

  // Track scroll depth in news articles
  trackArticleProgress(storyId, percentage) {
    this.trackUserAction('News', 'Article Progress', storyId, {
      percentage,
      timestamp: new Date().toISOString()
    });
  }
}

export const analytics = new AnalyticsService();
export default analytics;