import React, { useState, useEffect } from 'react';
import { 
  Syringe, Milk, Factory, Sun, Building2, Trophy, Award, Car, Plane,
  Home, CircleDollarSign, Wheat, Pickaxe, Ship, Shovel, Trees, Wind,
  Pill, PlaneLanding, Fish, Flame, List, ChevronRight, ChevronDown
} from 'lucide-react';
import { sectorIcons } from './watchlists';

const iconComponents = {
  'Syringe': Syringe,
  'Milk': Milk,
  'Factory': Factory,
  'Sun': Sun,
  'Building2': Building2,
  'Trophy': Trophy,
  'Award': Award,
  'Car': Car,
  'Plane': Plane,
  'Home': Home,
  'CircleDollarSign': CircleDollarSign,
  'Wheat': Wheat,
  'Pickaxe': Pickaxe,
  'Ship': Ship,
  'Shovel': Shovel,
  'Trees': Trees,
  'Wind': Wind,
  'Pill': Pill,
  'PlaneLanding': PlaneLanding,
  'Fish': Fish,
  'Flame': Flame,
  'List': List
};

const WatchlistSelector = ({ selectedWatchlist, onWatchlistChange }) => {
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const fetchWatchlists = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/watchlists`);
        if (!response.ok) {
          throw new Error('Failed to fetch watchlists');
        }
        const data = await response.json();
        setWatchlists(data.watchlists);
      } catch (err) {
        console.error('Error fetching watchlists:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWatchlists();
  }, []);

  if (loading) {
    return (
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 mb-6">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="h-16 bg-gray-100 rounded-lg animate-pulse"></div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 mb-6">
        Error loading watchlists. Please try again later.
      </div>
    );
  }

  // Sort watchlists alphabetically
  const sortedWatchlists = [...watchlists].sort((a, b) => a.name.localeCompare(b.name));
  const displayedWatchlists = isExpanded ? sortedWatchlists : sortedWatchlists.slice(0, 8);

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold text-light-text">Select Watchlist</h2>
        {watchlists.length > 8 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="flex items-center text-secondary hover:text-secondary-dark transition-colors"
          >
            {isExpanded ? (
              <>Show Less <ChevronDown className="w-4 h-4 ml-1" /></>
            ) : (
              <>Show All <ChevronRight className="w-4 h-4 ml-1" /></>
            )}
          </button>
        )}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
        {displayedWatchlists.map((watchlist) => {
          const IconComponent = iconComponents[sectorIcons[watchlist.name]] || List;
          
          return (
            <button
              key={watchlist.name}
              onClick={() => onWatchlistChange(watchlist.name)}
              className={`
                flex items-center p-3 rounded-lg border transition-all
                ${selectedWatchlist === watchlist.name 
                  ? 'border-secondary bg-secondary bg-opacity-5 text-secondary'
                  : 'border-gray-200 hover:border-secondary hover:bg-secondary hover:bg-opacity-5'}
              `}
            >
              <IconComponent className="w-4 h-4 mr-2 flex-shrink-0" />
              <div className="text-left">
                <div className="font-medium text-sm">{watchlist.name}</div>
                <div className="text-xs text-gray-500">
                  {watchlist.stockCount} companies · {watchlist.exchanges.join(', ')}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default WatchlistSelector;