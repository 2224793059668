import React from 'react';
import { Building2, Clock, Bell } from 'lucide-react';
import { Badge } from "../ui/badge";
import { Card, CardContent } from "../ui/card";

// Latest News Indicator Component
const LatestNewsIndicator = () => (
  <div className="relative inline-flex items-center">
    <Bell className="w-4 h-4 text-yellow-500" />
    <div className="absolute -top-1 -right-1 w-2 h-2">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
      <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-500"></span>
    </div>
  </div>
);

const getImportanceLabel = (level) => {
  switch (level) {
    case 3: return { label: 'High', color: 'bg-red-100 text-red-800' };
    case 2: return { label: 'Medium', color: 'bg-yellow-100 text-yellow-800' };
    case 1: return { label: 'Low', color: 'bg-green-100 text-green-800' };
    default: return { label: 'Unknown', color: 'bg-gray-100 text-gray-800' };
  }
};

const NewsCard = ({ story, onClick, onSectorClick }) => {
  const isRecent = new Date(story.timeStamps) > new Date(Date.now() - 24 * 60 * 60 * 1000);
  const importance = getImportanceLabel(story.importance);

  return (
    <Card
      className="group cursor-pointer transform transition-all duration-300 hover:shadow-lg hover:-translate-y-1 will-change-transform"
      onClick={() => onClick(story)}
    >
      <CardContent className="p-6">
        {/* Header Section */}
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Badge 
              variant="outline" 
              className="bg-white transition-colors duration-200 group-hover:border-secondary"
            >
              {story.exchange || 'Unknown'}
            </Badge>
            <Badge className={`${importance.color} transition-colors duration-200`}>
              {importance.label} Importance
            </Badge>
            {isRecent && <LatestNewsIndicator />}
          </div>
          <div className="flex items-center text-light-text-tertiary text-sm">
            <Clock className="w-4 h-4 mr-1" />
            {story.read_time || '3 min'} read
          </div>
        </div>
        
        {/* Title and Summary */}
        <h2 className="text-xl font-bold text-light-text mb-3 group-hover:text-secondary transition-colors duration-200">
          {story.title}
        </h2>
        <p className="text-light-text-secondary mb-4 line-clamp-3 leading-relaxed">
          {story.summary}
        </p>
        
        {/* Footer Section */}
        <div className="flex flex-wrap items-center justify-between gap-4">
          <div className="flex items-center text-light-text-secondary">
            <Building2 className="w-4 h-4 mr-2 text-secondary" />
            <span className="font-medium group-hover:text-secondary transition-colors duration-200">
              {story.company}
            </span>
            <span className="mx-2 text-gray-300">·</span>
            <span className="font-mono text-sm">{story.stock_code}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {story.sectors?.map((sector, index) => (
              <Badge
                key={index}
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/20 transition-colors duration-200"
                onClick={(e) => {
                  e.stopPropagation();
                  onSectorClick(sector);
                }}
              >
                {sector}
              </Badge>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default NewsCard;